/*--------------------------------------

	Guide-v

--------------------------------------*/
.guide-v {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-image: repeating-linear-gradient(#e4007d25 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #e4007d25 0 1px, transparent 1px 100%);
  background-size: 5vw 5vw;
  &.hidden {
    display: none;
  }
}

/*--------------------------------------

	Grid

--------------------------------------*/
.guide-l {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  .g {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .center {
    position: absolute;
    background-image: repeating-linear-gradient(#219edd2e 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #219edd2e 0 1px, transparent 1px 100%);
    /* 任意の数字に変更 */
    background-size: 50vw 50vh;
  }
  .out {
    width: 84vw;
    border-left: 1px #219edd2e solid;
    border-right: 1px #219edd2e solid;
  }
  .in {
    position: relative;
    max-width: 70vw;
    width: 620px;
    margin: 0 auto;
    border-left: 1px #219edd2e solid;
    border-right: 1px #219edd2e solid;
    top: -100vh;
  }
  &.hidden {
    display: none;
  }
}
