@charset "UTF-8";
*, :after, :before {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

address, blockquote, dl, figure, form, iframe, ol, p, pre, table, ul {
  margin: 0;
}

ol, ul {
  padding: 0;
  list-style: none;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}

pre {
  font-family: monospace;
  font-size: inherit;
}

address {
  font-style: inherit;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

embed, iframe, img, object, svg {
  vertical-align: bottom;
}

button, input, optgroup, select, textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: 0 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
}

[type=checkbox] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type=radio] {
  -webkit-appearance: radio;
  appearance: radio;
}

[type=button], [type=reset], [type=submit], button {
  cursor: pointer;
}

[type=button]:disabled, [type=reset]:disabled, [type=submit]:disabled, button:disabled {
  cursor: default;
}

:-moz-focusring {
  outline: auto;
}

select:disabled {
  opacity: inherit;
}

fieldset, option {
  padding: 0;
}

fieldset {
  margin: 0;
  min-width: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

label[for] {
  cursor: pointer;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[contenteditable]:focus {
  outline: auto;
}

table {
  border-color: inherit;
}

caption {
  text-align: left;
}

td, th {
  vertical-align: top;
  padding: 0;
}

th {
  text-align: left;
  font-weight: 700;
}

/*--------------------------------------

	Guide-v

--------------------------------------*/
.guide-v {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-image: repeating-linear-gradient(#e4007d25 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #e4007d25 0 1px, transparent 1px 100%);
  background-size: 5vw 5vw;
}
.guide-v.hidden {
  display: none;
}

/*--------------------------------------

	Grid

--------------------------------------*/
.guide-l {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
.guide-l .g {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.guide-l .center {
  position: absolute;
  background-image: repeating-linear-gradient(#219edd2e 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #219edd2e 0 1px, transparent 1px 100%);
  /* 任意の数字に変更 */
  background-size: 50vw 50vh;
}
.guide-l .out {
  width: 84vw;
  border-left: 1px #219edd2e solid;
  border-right: 1px #219edd2e solid;
}
.guide-l .in {
  position: relative;
  max-width: 70vw;
  width: 620px;
  margin: 0 auto;
  border-left: 1px #219edd2e solid;
  border-right: 1px #219edd2e solid;
  top: -100vh;
}
.guide-l.hidden {
  display: none;
}

/*----------------------------------

  Base

----------------------------------*/
/* 1 */
/*1-1*/
/*1-2*/
/*1-3*/
/*1-4*/
/*----------------------------------

  変数

----------------------------------*/
/*color*/
/*ease*/
/*フォントサイズ*/
.fs-ll {
  font-size: 2.9861111111vw;
  letter-spacing: 0.03em;
  font-weight: 600;
  line-height: 1.4;
}

.fs-l {
  font-size: 2.5694444444vw;
  letter-spacing: -0.008em;
  font-weight: 600;
  line-height: 1.5;
}

.fs-xm {
  font-size: 24px;
  font-weight: 600;
}

.fs-m {
  font-size: 17px;
  line-height: 1.8;
  letter-spacing: 0.05em;
}

.fs-s {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.05em;
}

.fs-xs {
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.05em;
}

/*フォントウェイト*/
.fw-m {
  font-weight: 600;
}

.android .c-title {
  font-weight: 700;
}
.android .fw-m {
  font-weight: 900;
}

/* スペース */
.spr {
  width: 100%;
}
.spr-w1 {
  height: 15vw;
}
.spr-w2 {
  height: 10vw;
}
.spr-w3 {
  height: 5vw;
}
.spr-w4 {
  height: 2.5vw;
}
.spr-w5 {
  height: 25px;
}
.spr-w6 {
  height: 15px;
}

/* 2 */
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  font-feature-settings: "palt";
  /*自動行間制御*/
  -webkit-font-smoothing: antialiased;
  /*アンチエイリアス*/
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f4f1ef;
  color: #333;
  font-family: "Montserrat", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "Yu Gothic", sans-serif, sans-serif;
  font-weight: 500;
}

main {
  position: relative;
}

.img {
  width: 100%;
  height: 100%;
}

.show-m {
  display: none;
}

.hide-m {
  display: block;
}

br {
  line-height: 0;
}

/*----------------------------------

    3.Brakepoints

----------------------------------*/
/*--------------------------------------

  Header / Footer

--------------------------------------*/
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: backdrop-filter 0.3s;
  pointer-events: all;
}
.l-header__body {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4vw 0;
}
.l-header__logo {
  width: 150px;
}
.l-header__nav {
  display: flex;
  align-items: center;
  margin-right: 5vw;
}
.l-header__nav-lists {
  display: flex;
  visibility: visible;
  pointer-events: all;
}
.l-header__nav-link {
  margin-right: 50px;
}
.l-header__menu {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: #0f3c3c;
  border: 1px solid #3a5e5e;
}
.l-header__menu button {
  width: 100%;
  height: 100%;
  position: relative;
}
.l-header__menu-hamb {
  height: 1.4rem;
  left: calc(50% - 1rem);
  position: absolute;
  top: calc(50% - 0.9rem);
  width: 2rem;
}
.l-header__menu-hamb line {
  stroke-width: 1.5;
  stroke: #fff;
  transition: 0.8s;
  transition-delay: 0.4s;
}
.l-header__menu-hamb line:nth-child(2) {
  transition-delay: 0.5s;
}
.l-header__menu-hamb line:nth-child(3) {
  transition-delay: 0.6s;
}
.l-header__menu-cross {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: calc(50% - 0.75rem);
  top: calc(50% - 0.75rem);
}
.l-header__menu-cross line {
  stroke-width: 1.5;
  transition: transform 0.8s;
  transform: translate(100%, 100%);
  transform-origin: center center;
  stroke: #fff;
  transition-delay: 0s;
}
.l-header__menu-cross line:first-child {
  transform: translate(100%, 100%);
}
.l-header__menu-cross line:last-child {
  transform: translate(100%, -100%);
}
.l-header__nav-list.is-navi {
  color: #009b4a;
}
.l-header__nav-list.is-navi .l-header__nav-link {
  cursor: default;
}
.l-header:hover {
  transition: backdrop-filter 0.3s;
  backdrop-filter: blur(1px);
}

.l-footer {
  width: 100%;
  height: 100%;
  position: relative;
  background: #0f3c3c;
  color: #fafafa;
  margin-top: -2px;
  padding-bottom: 2px;
}
.l-footer__left {
  width: 20vw;
}
.l-footer__right {
  width: 60vw;
}
.l-footer__body {
  width: 80vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.l-footer__logo-img {
  width: 10vw;
}
.l-footer__col {
  display: flex;
}
.l-footer__o-col {
  display: flex;
}
.l-footer__nav-list {
  width: 10vw;
}
.l-footer__g-nav {
  width: 55vw;
  justify-content: space-between;
}
.l-footer__o-nav {
  border-top: 1px solid #3a5e5e;
}
.l-footer__o-nav-link {
  margin-right: 40px;
}
.l-footer__wrap {
  width: 25vw;
  height: 100%;
}
.l-footer__list {
  padding-right: 20px;
}
.l-footer__list, .l-footer__nav-list {
  transition: opacity 0.4s;
}
.l-footer__list:hover, .l-footer__nav-list:hover {
  opacity: 0.5;
  transition: opacity 0.4s;
}
.l-footer__ttl {
  width: 100%;
  border-bottom: 1px solid #3a5e5e;
  padding-bottom: 25px;
  margin-bottom: 25px;
  opacity: 0.6;
}
.l-footer__bottom {
  display: flex;
  align-items: center;
}
.l-footer__bottom small {
  width: 100%;
  opacity: 0.6;
}
.l-footer__round {
  height: 100px;
  background: #0f3c3c;
  position: relative;
  margin-top: -1px;
}
.l-footer__round-body {
  width: 100%;
  top: -1px;
  position: relative;
  height: 10vh;
  overflow: hidden;
  height: 70px;
}
.l-footer__round-item {
  width: 150%;
  content: "";
  display: block;
  position: absolute;
  background: #faf9f7;
  height: 750%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -86.666%);
  z-index: 1;
}
.l-footer::after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 10vh;
  background: #0f3c3c;
  bottom: -10vh;
  left: 0;
}
.l-footer__bleed {
  width: 100vw;
  height: 5px;
  position: absolute;
  top: -4px;
  z-index: 5;
  background: #f4f1ef;
  display: none;
}

/*--------------------------------------

  load

--------------------------------------*/
.p-load, .l-load {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
}
.p-load__body, .l-load__body {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 120;
  left: 0;
}
.p-load__body-logo, .l-load__body-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #879e9e;
  font-size: 14px;
  font-weight: 600;
}
.p-load__body-logo svg, .l-load__body-logo svg {
  width: 10vw;
}
.p-load__body-logo path, .l-load__body-logo path {
  fill: #fff;
}
.p-load__body-logo .l-load__logo-number, .l-load__body-logo .l-load__logo-number {
  padding-top: 20px;
  width: 100%;
  display: inline-block;
}
.p-load__inner, .l-load__inner {
  width: 100vw;
  height: 100vh;
}
.p-load__text, .l-load__text {
  position: absolute;
}

.p-load__svg, .l-load__svg {
  z-index: 100;
  position: absolute;
  left: 0;
  top: -5px;
  width: 100%;
  height: calc(100% + 5px);
  fill: #0f3c3c;
}

.p-load__svg2, .l-load__svg2 {
  z-index: 80;
  position: absolute;
  left: 0;
  top: -5px;
  width: 100%;
  height: calc(100% + 5px);
  fill: #009b4a;
}

.p-load, .l-load {
  opacity: 1;
}

.u-rev {
  transform: scale(1, -1);
}

.p-load__inner {
  z-index: 50;
}

.p-load__progress {
  width: 80vw;
  z-index: 100;
  position: absolute;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 100vh;
}

.p-load_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.p-load_logo img {
  width: 12.5vw;
  display: inline-block;
}

.p-load_logo svg {
  width: 100px;
  height: 200px;
  fill: #fff;
}

.p-load_c span {
  display: inline-block;
}

.p-load_p, .p-load_t {
  font-size: 14px;
}

.p-load_p {
  left: 5vw;
  bottom: 5vw;
}

.p-load_c {
  position: absolute;
  right: -2.5vw;
  bottom: 5vw;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.p-load__in {
  display: inline-block;
  padding: 10px 0;
}

.spinner {
  animation: rotator 1.4s linear infinite;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 100%;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 2;
  stroke-width: 1px;
  transform-origin: center;
  stroke: #fff;
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
/*--------------------------------
	menu
--------------------------------*/
.p-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.p-menu__inner {
  width: 100vw;
  height: 100vh;
  margin: 0vw auto;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  z-index: 5;
}
.p-menu__img {
  height: 32vw;
  width: 25vw;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: 5;
  position: relative;
}
.p-menu__img-in {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transform-origin: top;
  z-index: 10;
  top: 100%;
}
.p-menu__img-wrap {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.p-menu__tmb {
  height: 35vw !important;
}
.p-menu__wrap {
  width: 50%;
}
.p-menu__bg {
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
}
.p-menu__bg svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  fill: #f4f1ee;
}
.p-menu__left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-menu__nav {
  padding: 0 10vw;
}
.p-menu__bar {
  display: block;
  height: 1%;
  transform: scaleY(0);
  width: 1px;
  position: absolute;
  right: 50%;
  top: 0;
  transform-origin: left top;
  background: #d0d0d0;
}
.p-menu .is-menuimg {
  z-index: 15;
  opacity: 1;
  transition: 0.8s;
  filter: brightness(1);
  transform: scale(1);
}
.p-menu__img-in {
  opacity: 0;
  transition: 0.8s;
  filter: brightness(1.3);
  z-index: 15;
  top: 0;
  transform: scale(1.1);
}
.p-menu__img-in.is-navi {
  z-index: 15;
  top: 0;
  opacity: 1;
  filter: brightness(1);
  transform: scale(1);
}
.p-menu__img-in.is-default {
  z-index: 15;
  top: 0;
  opacity: 1;
}
.p-menu__nav-lists {
  padding-bottom: 20px;
}
.p-menu__nav-primary {
  margin-left: -5px;
}
.p-menu__nav-primary .p-menu__nav-link {
  font-size: 50px;
}
.p-menu__nav-primary .p-menu__nav-list {
  padding: 0.2em 0;
}
.p-menu__nav-secondary .p-menu__nav-link {
  font-size: 22px;
}
.p-menu__nav-secondary .p-menu__nav-list {
  padding: 0.5em 0 0.5em 0;
}
.p-menu__nav-tertiary .p-menu__nav-list {
  padding: 0.5em 0;
}
.p-menu__nav-link {
  color: #0f3c3c;
  position: relative;
  height: 100%;
  overflow: hidden;
  display: inline-block;
}
.p-menu__nav-link span {
  display: inline-block;
}
.p-menu__mask {
  fill: #009b4a;
}
.p-menu .p-menu__nav-link:hover span {
  color: #009b4a;
  transition: 0.4s color;
}
.p-menu__nav-list {
  width: fit-content;
  position: relative;
}
.p-menu__nav-list span {
  transition: 0.4s color;
}
.p-menu__nav-list.is-navi .u-dot {
  display: inline-block;
  background: #009b4a;
  border-radius: 100%;
  content: "";
  height: 5px;
  width: 5px;
  left: -20px;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translate(-50%, -50%);
}
.p-menu__nav-list.is-navi .p-menu__nav-link {
  color: #009b4a;
}
.p-menu__over {
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  pointer-events: none;
}

.l-header__menu {
  position: relative;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: fixed;
  top: calc(4vw - 17.5px);
  right: 5vw;
  z-index: 10;
}
.l-header__menu:hover {
  transform: scale(1.1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.is-menu-open .p-menu {
  opacity: 1;
  pointer-events: auto;
  visibility: inherit;
}
.is-menu-open .c-menu__btn span {
  display: block;
  width: 10%;
  height: 1%;
  border-radius: 0%;
  background: #222;
  transition: 0.4s;
  cursor: pointer;
}
.is-menu-open .l-header {
  pointer-events: none;
}
.is-menu-open .l-header__logo {
  pointer-events: all;
}
.is-menu-open .l-header__nav-lists {
  pointer-events: none;
}

.c-menu__btn.is-menu-open .l-header__menu-cross line {
  transform: translate(0);
  transition-delay: 0.5s;
}
.c-menu__btn.is-menu-open .l-header__menu-hamb line {
  transform: translateX(5rem);
}
.c-menu__btn.is-menu-open .l-header__menu-hamb line {
  transition-delay: 0s;
}
.c-menu__btn.is-menu-open .l-header__menu-hamb line:nth-child(2) {
  transition-delay: 0.1s;
}
.c-menu__btn.is-menu-open .l-header__menu-hamb line:nth-child(3) {
  transition-delay: 0.2s;
}

/*--------------------------------------

  compornant

--------------------------------------*/
/* body */
.c-inner {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

/* section */
.c-sec__body {
  width: 90vw;
  margin: 0 auto;
}

.c-sec__ttl {
  position: relative;
  width: 90vw;
  margin: 0 auto;
  border-bottom: 1px solid #d0d0d0;
  color: #0f3c3c;
}
.c-sec__ttl--text {
  display: block;
  margin-left: 5vw;
  margin-bottom: 40px;
  font-size: 2.7777777778vw;
  font-weight: 600;
  position: relative;
}
.c-sec__ttl--text::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #009b4a;
  position: relative;
  position: absolute;
  left: -2.5vw;
  top: 0;
  right: auto;
  bottom: 0;
  margin: auto;
}
.c-sec__ttl-contact {
  width: 100%;
}
.c-sec__ttl-contact .c-sec__ttl--text {
  font-size: 24px;
}

/* colom */
.c-col {
  display: flex;
  width: 80vw;
  margin: 0 auto;
  align-items: center;
}
.c-col__contact, .c-col__profile, .c-col__news {
  align-items: flex-start;
}
.c-col__news {
  display: flex;
}
.c-col.c-col__top {
  align-items: flex-start;
}
.c-col__inner {
  width: 50%;
  height: 100%;
  position: relative;
}
.c-col__inner--ask {
  border-bottom: none;
  margin-left: -5vw;
  width: 40vw;
}
.c-col__inner--wide {
  width: 60%;
}
.c-col__inner-lead {
  padding-right: 10vw;
}
.c-col__inner-left {
  padding-right: 5vw;
}
.c-col .u-col__careers {
  width: 80%;
}
.c-col__inner-right {
  padding-left: 5vw;
}
.c-col__inner--info {
  width: 55%;
  margin-left: -5vw;
}
.c-col__inner-porker {
  width: 45%;
  margin-left: 5vw;
}
.c-col__inner-mini {
  width: 80vw;
  margin: 0 auto;
}
.c-col__inner-news {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 5;
}
.c-col__inner-slidenav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.c-col__text-mini {
  padding: 0 2.5vw 0 5vw;
}
.c-col__text-right {
  margin-right: 2.5vw;
}
.c-col__text-job {
  margin: 0 5vw;
}
.c-col__text-contact {
  padding-left: 5vw;
  padding-right: 1.8vw;
}
.c-col__text--info {
  margin-right: 5vw;
}
.c-col__img {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  z-index: 5;
}
.c-col__logo {
  margin: 0 10vw;
}
.c-col__canvas {
  width: 45vw;
  height: 35vw;
  margin-left: -5vw;
  display: flex;
  align-items: center;
}
.c-col__canvas canvas {
  margin-left: -5vw;
  margin-top: -5vw;
  position: absolute;
}
.c-col__img-big {
  width: 42.5vw;
  margin-right: -2.5vw;
  padding-top: 42.5vw;
}
.c-col__img-mini {
  width: 30vw;
  margin-right: -2.5vw;
  padding-top: 20vw;
}
.c-col__img-job {
  width: 35vw;
  margin-right: 0vw;
  margin-left: auto;
  padding-top: 22.5vw;
  background-color: #0f3c3c;
}
.c-col__img-info {
  width: 25vw;
  padding-top: 25vw;
}
.c-col__img-profile {
  width: 30vw;
  padding-top: 45vw;
}
.c-col__img-contact {
  width: 40vw;
  margin-left: -10vw;
  padding-top: 60vw;
  border-radius: 0 20px 20px 0;
}
.c-col__figure {
  width: 37.5vw;
  height: 37.5vw;
  text-align: right;
  margin-left: auto;
  margin-right: -2.5vw;
}

.c-figure {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-figure__base {
  transform: translate(-50%, -50%) rotate(-90deg) scale(1, -1);
}
.c-figure__base svg {
  animation: r360-r 40s infinite linear;
}
.c-figure__base-cover {
  fill: transparent;
  stroke: #0f3c3c;
  stroke-width: 5;
}
.c-figure__base-cover {
  stroke-dasharray: 366 366;
  stroke-dashoffset: 0;
}
.c-figure__base-dash {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.6;
  stroke-dasharray: 0 1.7;
  stroke-linecap: round;
  opacity: 0.6;
}
.c-figure__base {
  bottom: 50%;
  height: 80%;
  left: 50%;
  position: absolute;
  right: 50%;
  top: 55%;
  width: 80%;
}
@keyframes circle {
  0% {
    stroke-dasharray: 366 366;
  }
  99.9%, to {
    stroke-dasharray: 0 366;
  }
}
.c-figure__item {
  opacity: 0;
  background: #fafafa;
  border-radius: 100%;
  height: 34%;
  width: 34%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-figure__ai {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.8s;
  transition-delay: 0.4s;
}
.c-figure__ict {
  position: absolute;
  bottom: 6%;
  left: 3%;
  transition: 0.8s;
  transition-delay: 0.5s;
}
.c-figure__iot {
  position: absolute;
  bottom: 6%;
  right: 3%;
  transition: 0.8s;
  transition-delay: 0.6s;
}
.c-figure__label-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
.c-figure__label {
  padding: 2px 10px;
  background: #009b4a;
  border-radius: 100vw;
  text-align: center;
  opacity: 0;
  transition: 0.8s;
  transition-delay: 0.6s;
}
.c-figure__label-01 {
  position: absolute;
  left: 5%;
  top: 38%;
  transform: translate(-50%, -50%);
}
.c-figure__label-02 {
  position: absolute;
  right: -5%;
  top: 38%;
  transform: translate(-50%, -50%);
}
.c-figure__label-03 {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-figure__ttl-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.c-figure__ttl-inner {
  width: 35%;
  text-align: center;
  opacity: 0;
}
.c-figure__logo {
  width: 90%;
  display: flex;
  margin: 0 auto;
  padding: 7% 0 0 0;
}
.c-figure__logo svg {
  width: 100%;
  height: 100%;
}
.c-figure__arrow-wrap {
  animation: r360 7s infinite linear;
  display: flex;
  justify-content: center;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 15%;
  opacity: 0;
}
.c-figure__arrow {
  width: 3%;
  height: auto;
}
.c-figure .aw-2 {
  transform: rotate(120deg);
  margin-top: 0vw;
}
.c-figure .aw-3 {
  transform: rotate(236deg);
  margin-top: -3vw;
}
@keyframes r360 {
  0% {
    transform: rotate(20deg);
  }
  99.9%, to {
    transform: rotate(110deg);
  }
}
@keyframes r360-r {
  0% {
    transform: rotate(0deg);
  }
  99.9%, to {
    transform: rotate(-360deg);
  }
}
.c-figure.is-on .c-figure__base-cover {
  animation: circle 3s forwards;
}
.c-figure.is-on .c-figure__label, .c-figure.is-on .c-figure__item {
  opacity: 1;
}
.c-figure.is-on .c-figure__ai {
  top: 20%;
}
.c-figure.is-on .c-figure__ict {
  bottom: 8%;
}
.c-figure.is-on .c-figure__iot {
  bottom: 8%;
}
.c-figure.is-on .c-figure__arrow-wrap {
  opacity: 1;
  transition: 0.8s;
}
.c-figure.is-on .c-figure__ttl-inner {
  opacity: 1;
  transition: 0.4s;
}

/* news */
.c-news__desc {
  display: flex;
  align-items: center;
}
.c-news__desc-line {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e4e4e4;
}
.c-news__desc-data {
  font-size: 14px;
  margin-right: 20px;
  opacity: 0.8;
}
.c-news__desc-cate {
  font-size: 12px;
  font-weight: 600;
  padding: 2px 6px;
  color: #009b4a;
  border: 1px solid #009b4a;
  border-radius: 100px;
  z-index: 5;
}
.c-news__list-text {
  margin-bottom: 30px;
}
.c-news__list-link {
  color: #0f3c3c;
}
.c-news__desc-site {
  color: #0f3c3c;
}

/* btn */
/* 通常リンク */
.c-btn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.c-btn_body {
  display: flex;
  align-items: center;
}
.c-btn__text {
  margin-right: 2rem;
  color: #0f3c3c;
}
.c-btn__circle {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #009b4a;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  position: relative;
  overflow: hidden;
}
.c-btn__circle-arrow {
  width: 20px;
  height: 16px;
  position: relative;
  display: inline-block;
  z-index: 3;
}
.c-btn__circle-arrow svg {
  position: absolute;
  top: 0;
  left: 0;
}
.c-btn__circl-mini {
  display: inline-block;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-btn__circle-dl {
  transform: rotate(90deg);
  margin-top: -2px;
}
.c-btn__circle-dl::after {
  border-right: 1px solid #fff;
  content: "";
  width: 110%;
  height: 100%;
  display: block;
  bottom: 0;
  position: absolute;
  margin: 0;
}
.c-btn__circle-arrow-mini {
  width: 10px;
  height: 8px;
  position: relative;
}
.c-btn__circle-arrow-top {
  width: 8px;
  height: 10px;
  position: relative;
}
.c-btn__circle-arrow-mini svg {
  position: absolute;
  top: 0;
  left: 0;
}
.c-btn_left {
  justify-content: flex-start;
}
.c-btn-acc {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #009b4a;
  cursor: pointer;
  border-radius: 100%;
  position: relative;
  transition: 0.5s;
}
.c-btn-slide {
  cursor: pointer;
}
.c-btn-slide.is-navi {
  cursor: default;
  opacity: 0.5;
}
.c-btn__dots {
  width: 100%;
  z-index: 20;
}
.c-btn__dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  border-radius: 50%;
  display: inline-block;
  height: 3px;
  width: 3px;
  background: #fff;
}
.c-btn__dot:nth-child(1) {
  left: 12px;
}
.c-btn__dot:nth-child(3) {
  left: 23px;
}
.c-btn__circle-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0, 0);
  border-radius: 100%;
}

/* scroll */
.c-scroll-btn {
  background-color: #009b4a;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vw;
  width: 10vw;
  cursor: pointer;
}
.c-scroll-btn__arrow {
  width: 20px;
  height: 24px;
  position: relative;
}
.c-scroll-btn__arrow svg {
  position: absolute;
  top: 0;
  left: 0;
}

/* c-ask */
.c-ask {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 35px;
  margin-bottom: 35px;
}
.c-ask__q {
  display: flex;
}
.c-ask__nm {
  width: 5vw;
  font-weight: 600;
}
.c-ask__en {
  font-weight: 600;
  padding-bottom: 10px;
  opacity: 0.5;
}
.c-ask__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.c-ask__text span {
  display: block;
}
.c-ask__text h4 {
  display: block;
}
.c-ask__body--text {
  background: #f4f1ee;
  padding: 5vw;
  margin-top: 2.5vw;
  border-radius: 20px;
}
.c-ask__title.is-active .c-btn-acc {
  background-color: #0f3c3c;
  transform: rotate(90deg);
  transition: 0.5s;
}

/*　social　*/
.c-social__link {
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s opacity;
}
.c-social__link :hover .normal {
  stroke-width: 0;
  transition: 0.5s;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
}
.c-social__circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-85deg);
}
.c-social__circle .normal {
  stroke: #275050;
  stroke-width: 1px;
  transition: 0.5s;
  stroke-dasharray: 130;
  stroke-dashoffset: 0;
}
.c-social__circle .hover {
  transition: 0.5s;
}
.c-social__circle svg {
  transition: 0.5s;
}
.c-social__icon {
  width: 15px;
  height: 100%;
  max-height: 15px;
  display: inline-block;
  opacity: 1;
}

/* news */
.p-news {
  background-color: #f4f2ee;
}
.p-news__list-inner {
  width: 25vw;
  display: inline-block;
  cursor: pointer;
}
.p-news__lists {
  align-items: flex-start;
  display: flex;
  margin: 0 auto;
  width: 80vw;
  justify-content: space-between;
}

.p-wave {
  width: 100vw;
  overflow: hidden;
}
.p-wave__body {
  white-space: nowrap;
  left: 0;
}
.p-wave__inner {
  display: inline-block;
  animation: 35s wave infinite linear;
}
.p-wave__item {
  display: inline-block;
  color: #dcdcdc;
  font-weight: 600;
  margin: 0 1.5vw;
  letter-spacing: 0.5vw;
  vertical-align: middle;
}
.p-wave__text svg {
  fill: #dcdcdc;
  height: 8vw;
}
.p-wave__icon {
  width: 9.7222222222vw;
  height: 9.7222222222vw;
  position: relative;
  margin: 0 2.5vw;
}
.p-wave__icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  max-height: 4vw;
  max-width: 10vw;
  width: 40%;
  height: 35%;
}

@keyframes wave {
  100% {
    transform: translateX(-100%);
  }
}
/* sldieshow */
.c-show {
  z-index: 5;
}
.c-show__img {
  z-index: 5;
}
.c-show__img-body {
  width: 45vw;
  height: 55vw;
  margin-left: -10vw;
  overflow: hidden;
  margin-right: 5vw;
  position: relative;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
  z-index: 5;
}
.c-show__item {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: scale(1);
}
.c-show__item {
  transition: 0.8s;
}
.c-show__item.is-offslide {
  opacity: 0;
  transform: scale(1.05);
}
.c-show__item-0 {
  background: #0f3c3c;
  color: #fafafa;
}
.c-show__figure {
  height: 35vw;
  text-align: right;
  width: 35vw;
  margin: 0 auto;
}
.c-show .c-figure {
  height: 100%;
}
.c-show__nav-wrap {
  position: absolute;
  right: calc(5vw - 40px);
  bottom: 30px;
  width: 20px;
  height: 20px;
}
.c-show__nav {
  position: absolute;
  width: 20px;
  height: 20px;
}
.c-show__nav svg {
  position: absolute;
  width: 20px;
  height: 20px;
}
.c-show__indicator {
  cursor: default;
  display: block;
  position: relative;
}
.c-show .arrow-gauge {
  z-index: 5;
}
.c-show .circle-state {
  fill: transparent;
  stroke-width: 6;
  stroke: #009b4a;
  z-index: 5;
  stroke-dasharray: 343 360;
}
.c-show .circle-base {
  fill: transparent;
  stroke-width: 6;
  stroke: #e1e1e0;
  z-index: 1;
}
.c-show .c-show__indicator {
  height: 20px;
}
.c-show__arrow {
  transform: rotate(-90deg);
  position: absolute;
}
.c-show__indicator {
  cursor: pointer;
}
.c-show__indicator:first-child {
  margin-bottom: 10px;
}
.c-show__indicator::before {
  background: #d0d0d0;
  content: "";
  height: 4px;
  width: 4px;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.c-show__indicator.is-offslide {
  cursor: default;
}
.c-show__indicator.is-offslide::before {
  transition: 0.5s;
  background: #0f3c3c;
  content: "";
  height: 4px;
  width: 4px;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.c-show__indicator.is-offslide .c-show__nav {
  opacity: 1;
  transition: 0.5s;
}
.c-show__indicator .c-show__nav {
  opacity: 0;
  transition: 0.5s;
}

/* info */
.c-info__ttl {
  border-bottom: 1px solid #d0d0d0;
  color: #0f3c3c;
  padding-bottom: 25px;
}
.c-info__btn-wrap {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.c-info__btn-wrap .c-btn {
  width: 18vw;
}

/*--------------------------------------

  Utility

--------------------------------------*/
/* 下線 */
.u-green__line {
  border-bottom: 1px solid #3a5e5e;
}

.u-topline {
  border-top: 1px solid #d0d0d0;
}

.u-noline {
  border-bottom: none;
  border-top: none;
}

/* テキスト */
.u-green__text {
  color: #009b4a;
}

.u-deep__text {
  color: #0f3c3c;
}

.u-white__text {
  color: #fafafa;
}

/*　状態　*/
.is-hidden {
  height: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  z-index: 5;
}

.is-active {
  height: var(--h);
  position: relative;
  width: 100%;
  overflow: inherit;
  transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 1;
  z-index: 5;
}

.is-stop {
  animation-play-state: paused;
}

.is-loading {
  cursor: wait;
}

/*矢印*/
.u-arrow--external {
  transform: rotate(-45deg);
}

.u-arrow--prev {
  transform: rotate(-90deg);
}

.u-arrow--next {
  transform: rotate(90deg);
}

.u-arrow--bottom {
  transform: rotate(180deg);
}

.u-arrow--explore {
  transform: rotate(45deg);
  height: 10px;
  position: relative;
  width: 8px;
}

.u-arrow--explore path {
  fill: #0f3c3c;
}

/**/
.u-bg-green {
  background: #009b4a;
  z-index: 2;
}

.u-bg-deep {
  background: #0f3c3c;
  z-index: 0;
  position: absolute;
}

.u-bg-white {
  background: #19a65c;
  z-index: 0;
  position: absolute;
  display: none;
}

.u-bg-base {
  background: #f4f1ee;
  z-index: 1;
}

.u-border {
  border: #275050 1px solid;
}

/* temp */
.u-list {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 5px;
}

.u-list ::before {
  content: "";
  background: #009b4a;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 0;
  top: 0.65em;
  width: 4px;
  height: 4px;
}

.u-list--green ::before {
  background: #0f3c3c;
}

/* link */
.u-br {
  display: inline-block;
}

.u-map__link {
  vertical-align: middle;
  padding: 0px 8px;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-bottom: 3px;
}

.u-map__link {
  color: rgba(34, 34, 34, 0.501);
}

/*位置*/
.u-ttl__mini {
  position: absolute;
  right: 5vw;
  top: 0;
  top: 30%;
  align-items: flex-start;
}

.u-title__jobs {
  width: 100%;
}

.u-text__right {
  text-align: right;
}

.u-list {
  width: 100%;
}

.show-t {
  display: none;
}

/* ベージュbg */
.u-deep-bg {
  background: #f4f1ee;
}

/*--------------------------------------

  Lazy+image

--------------------------------------*/
.js-img {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  filter: brightness(1.3);
}

.js-bg {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  filter: brightness(1.3);
}

.is-loaded {
  opacity: 1;
  transition: opacity 0.5s, filter 0.5s;
  filter: brightness(1);
}
.is-loaded.u-no {
  transition: none;
}

.js-img__bg {
  background: #222;
}

.js-img--bg {
  background: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*----------------------------------

  SmootherScroll

----------------------------------*/
#sc-wrap {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

main {
  background: #faf9f7;
}

/*--------------------------------------

  Animation

--------------------------------------*/
/*--------------------------------------
  Hover
--------------------------------------*/
.js-link-text__in {
  position: relative;
  height: 50%;
  overflow: hidden;
}
.js-link-text__wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;
  display: flex;
  align-items: center;
}
.js-link-text__wrap span {
  display: inline-block;
}
.js-link-text__label {
  display: inline-block;
}
.js-link-text__hover {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(100%);
  display: inline-block;
  overflow: hidden;
}

.js-link-btn {
  transition: transform 0.5s;
}
.js-link-btn:hover {
  transform: scale(0.95);
  transition: transform 0.5s;
}
.js-link-btn__in {
  height: auto;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.js-link-btn__in span {
  padding: 10px 0;
}

.js-link-mini {
  height: 100%;
  position: relative;
  display: inline-block;
  transition: 0.35s color;
}
.js-link-mini__wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 40px;
  z-index: -1;
  transition: 0.35s transform;
}
.js-link-mini:hover {
  color: #fafafa;
  transition: 0.35s color;
}
.js-link-mini:hover .js-link-mini__wrap {
  opacity: 1;
  transition: 0.35s opacity;
}

/*--------------------------------------
  Hover
--------------------------------------*/
.js-link {
  cursor: pointer;
}
.js-link-line {
  position: relative;
}
.js-link-line::before, .js-link-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  height: 1px;
  background: #0f3c3c;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.js-link-line::before {
  transform: scale(1, 1);
  transform-origin: left top;
}
.js-link-line::after {
  transform: scale(0, 1);
  transform-origin: right top;
  transition-delay: 0.5s;
}
.js-link:hover .p-single__nav__thumb-inner,
.js-link:hover .p-allnews__thumb-inner {
  transition: transform 1s;
  transform: scale(1.05);
}
.js-link:hover .js-link-line::before {
  transform: scale(0, 1);
  transform-origin: right top;
}
.js-link:hover .js-link-line::after {
  transition-delay: 0.2s;
  transform: scale(1, 1);
  transform-origin: left top;
}

/*--------------------------------------
  TTL
--------------------------------------*/
.js-ttl {
  display: inline-block;
  overflow: hidden;
  height: 100%;
}
.js-ttl__text {
  display: inline-block;
  transform-origin: left top;
}

.u-in {
  display: inline-block;
  opacity: 0;
  transform: translateY(30px);
  transition: transform 0.55s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.55s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.u-enter {
  display: inline-block;
  opacity: 1;
  transform: translateY(0px);
  transition: transform 0.55s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.55s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.l-header__logo, .l-header__nav-lists {
  transform: translateY(0px);
  opacity: 1;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.down .l-header {
  pointer-events: none;
}
.down .l-header__logo, .down .l-header__nav-lists {
  transform: translateY(-30px);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.is-text__white .l-header__nav-list {
  color: white;
}
.is-text__white .l-header__nav-list.is-navi {
  color: #009b4a;
}

/*--------------------------------------

  home.scss

--------------------------------------*/
.p-fv {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f4f1ee;
}
.p-fv__body {
  padding: 0 10vw;
  position: relative;
  height: 100vh;
  z-index: 2;
  top: 0;
}
.p-fv__title {
  position: absolute;
  bottom: 5vw;
  color: #0f3c3c;
}
.p-fv__title span {
  display: block;
  font-weight: 600;
}
.p-fv__title .t1 {
  font-size: 2.7777777778vw;
  margin-bottom: 1.6vw;
}
.p-fv__title .t2, .p-fv .t3 {
  font-size: 6.9444444444vw;
}
.p-fv__round {
  height: 100px;
  background: #faf9f7;
  margin-top: -5px;
  position: relative;
  display: block;
  z-index: 5;
}
.p-fv__round-body {
  width: 100%;
  top: 0;
  position: relative;
  height: 10vh;
  overflow: hidden;
  height: 70px;
  background: #faf9f7;
}
.p-fv__round-item {
  width: 150%;
  content: "";
  display: block;
  position: absolute;
  background: #f4f1ee;
  height: 750%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -86.666%);
  z-index: 1;
}
.p-fv__scroll-btn {
  position: absolute;
  bottom: 5vw;
  right: 0;
}
.p-fv__o {
  display: block;
  overflow: hidden;
}

.service-body {
  background: #0f3c3c;
  color: #fafafa;
}

.mv {
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 50vw;
  pointer-events: none;
  background: #f4f1ee;
  position: absolute;
  top: 0;
  left: 0;
}

.space {
  top: 3vw;
  background: #faf9f7;
  z-index: 10;
  height: 50vh;
}

/*--------------------------------------

  page.scss

--------------------------------------*/
.c-sub-fv {
  display: block;
  height: 100%;
  width: 100%;
}
.c-sub-fv__ttl {
  width: 80vw;
  margin: 0 auto;
  position: relative;
  height: 45vw;
  z-index: 2;
}
.c-sub-fv__ttl-small {
  height: auto;
}
.c-sub-fv__ttl h2 {
  color: #009b4a;
  font-size: 18px;
}
.c-sub-fv__ttl h3 {
  font-size: 3.8194444444vw;
  letter-spacing: 0.02em;
  line-height: 1.3;
}
.c-sub-fv__img {
  overflow: hidden;
  position: relative;
  width: 95%;
  height: 105vh;
  margin: 0 auto;
  z-index: 1;
  border-radius: 20px;
  background: #f4f1ee;
}
.c-sub-fv__img img {
  border-radius: 20px;
}
.c-sub-fv__scroll-btn {
  position: absolute;
  bottom: -5vw;
  right: 0;
}

.c-sub-reed__inner {
  margin-left: 40vw;
  width: 42.5vw;
}

.p-vision {
  position: relative;
}
.p-vision__img {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 2;
}
.p-vision__img-bg {
  border-radius: 0px;
  width: 90%;
  height: 100%;
  display: block;
  margin-left: 5vw;
  border-radius: 20px;
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 3;
}
.p-vision__img-inner {
  width: 90%;
  margin: 0 auto;
  position: relative;
  height: 100%;
}
.p-vision__img-item {
  transform: scale(1);
  z-index: 2;
  width: 100vw;
  margin-left: -5vw;
  border-radius: 0px;
  position: absolute;
}
.p-vision__texts {
  z-index: 5;
  color: #fafafa;
  width: 80vw;
  margin: 0 5vw;
}
.p-vision__texts {
  z-index: 5;
  color: #fafafa;
}
.p-vision__ttl {
  font-size: 3.4722222222vw;
}
.p-vision__ttl span {
  display: block;
  line-height: 1.3;
}
.p-vision__coment {
  width: 45%;
}

.p-products {
  background-color: #f4f1ee;
}

.p-products {
  background-color: #f4f2ee;
}
.p-products__list-inner {
  width: 25vw;
  display: inline-block;
  cursor: pointer;
}
.p-products__lists {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
  margin: 0 auto;
  width: 80vw;
  justify-content: space-between;
}
.p-products__lists::after {
  content: "";
  display: block;
  width: 25vw;
}
.p-products__list {
  width: 25vw;
  position: relative;
  margin-bottom: 7.5vw;
}
.p-products__img {
  padding-top: 17.5vw;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  z-index: 5;
}
.p-products__img img {
  transform: scale(1.05);
}
.p-products__text {
  padding-right: 10px;
  line-height: 1.8;
}
.p-products small {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  opacity: 0.8;
  line-height: 1.8;
}

/*----------------------------------
  keenslider
----------------------------------*/
.p-team, .p-people {
  width: 100vw;
  overflow: hidden;
}
.p-team__slider, .p-people__slider {
  width: 100vw;
  margin-left: -5vw;
}
.p-team__slider--body, .p-people__slider--body {
  width: 80vw;
  position: relative;
  padding-left: 10vw;
  overflow: visible;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.p-team__slider--body.is-drag .p-team__slide--img, .p-team__slider--body.is-drag .p-people__slide--img, .p-people__slider--body.is-drag .p-team__slide--img, .p-people__slider--body.is-drag .p-people__slide--img {
  cursor: grabbing;
}
.p-team__slide, .p-people__slide {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-right: 5vw;
  height: 100%;
}
.p-team__slide, .p-people__slide {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.p-team__slide--inner, .p-people__slide--inner {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #d0d0d0;
}
.p-team .p-people__slide--inner, .p-people .p-people__slide--inner {
  border-bottom: none;
}
.p-team__slide--img, .p-people__slide--img {
  position: relative;
  padding-top: 130%;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  z-index: 5;
  cursor: grab;
  background: #f4f1ee;
}
.p-team__slide--name, .p-people__slide--name {
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-team__slide--text, .p-people__slide--text {
  padding-bottom: 2.5vw;
}
.p-team__slide--profile, .p-people__slide--profile {
  transition: 1s;
}
.p-team__slide-nav, .p-people__slide-nav {
  display: flex;
  justify-content: space-between;
}
.p-team__slide-nav div, .p-people__slide-nav div {
  margin: 0 5px;
}
.p-team__slide-current, .p-people__slide-current {
  width: 10vw;
  background: #d0d0d0;
  margin-right: 20px;
  position: relative;
  height: 1px;
  overflow: hidden;
}
.p-team__slide--desc.is-active .c-btn-acc, .p-people__slide--desc.is-active .c-btn-acc {
  background-color: #0f3c3c;
  transform: rotate(90deg);
  transition: 0.5s;
}

.u-slide__current {
  content: "";
  width: 0%;
  height: 100%;
  background: #009b4a;
  position: absolute;
  display: inline-block;
}

.p-company__list {
  padding-bottom: 25px;
}
.p-company__list h5 {
  padding-bottom: 10px;
}

.slide-img {
  width: 130%;
  left: -15%;
}

.p-jobs__body {
  background: #f4f1ee;
  border-radius: 20px;
}

.p-explore {
  background: #f4f1ee;
}
.p-explore__link {
  display: flex;
  justify-content: space-between;
}
.p-explore__link h4 {
  opacity: 0.5;
}
.p-explore__link-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
}
.p-explore__link-item {
  width: 50%;
}
.p-explore__link-item .c-btn_body {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
  width: 20vw;
  margin-left: 5vw;
  padding: 25px 0;
}
.p-explore .p-allnews__list {
  border-bottom: 1px solid #d0d0d0;
  border-top: none;
}

.c-dropdown {
  background: #0f3c3c;
  display: inline-block;
  position: absolute;
  top: 0;
  overflow: hidden;
  border-radius: 30px;
  margin: 0 0 0 auto;
  cursor: pointer;
  z-index: 5;
  min-width: 15vw;
}
.c-dropdown-list {
  padding: 15px 30px;
  width: 100%;
  height: 100%;
  transition: background-color 0.2s;
}
.c-dropdown-list:hover {
  background: #154a4a;
  transition: background-color 0.2s;
}
.c-dropdown-list a {
  width: 100%;
  height: 100%;
  display: block;
}
.c-dropdown__title {
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c-dropdown .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #9fb1b1 transparent transparent transparent;
}

.p-single__nav,
.p-allnews {
  width: 80vw;
  margin: 0 auto;
}
.p-single__nav__article,
.p-allnews__article {
  display: flex;
  align-items: center;
  position: relative;
}
.p-single__nav__list,
.p-allnews__list {
  border-top: 1px solid #d0d0d0;
}
.p-single__nav__info,
.p-allnews__info {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
}
.p-single__nav__thumb-wrap,
.p-allnews__thumb-wrap {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  z-index: 5;
  position: relative;
}
.p-single__nav__thumb,
.p-allnews__thumb {
  width: 20vw;
  margin-right: 5vw;
}
.p-single__nav__thumb-inner,
.p-allnews__thumb-inner {
  width: 100%;
  padding-top: 67.2%;
  position: relative;
  overflow: hidden;
  transition: transform 1s;
  border-radius: 0px;
  z-index: 5;
}
.p-single__nav__body,
.p-allnews__body {
  width: 55vw;
  z-index: 2;
}
.p-single__nav__text,
.p-allnews__text {
  width: 45vw;
}
.p-single__nav__article-link,
.p-allnews__article-link {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.p-single__nav__article .show-m,
.p-allnews__article .show-m {
  display: none;
}

.p-pagination {
  border-top: 1px solid #d0d0d0;
  position: relative;
}
.p-pagination__inner {
  display: flex;
  justify-content: center;
}
.p-pagination a.prev.page-numbers {
  display: inline-block;
  position: absolute;
  left: 15px;
  border: 0;
}
.p-pagination .page-numbers span {
  line-height: 34px;
}
.p-pagination a.next.page-numbers {
  display: inline-block;
  position: absolute;
  right: 0;
  border: 0;
}
.p-pagination .p-pagination__prev.c-btn {
  justify-content: flex-start;
  margin-left: -15px;
}
.p-pagination a.prev.page-numbers .c-btn__text {
  padding-left: 15px;
}
.p-pagination__page, .p-pagination .page-numbers {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  text-align: center;
  line-height: 36px;
  margin: 0 0.5rem;
  border: 1px solid #5c7170;
}
.p-pagination__acthive, .p-pagination .current {
  background: #009b4a;
  border: 1px solid #009b4a;
}
.p-pagination__acthive, .p-pagination .current span {
  color: #fafafa;
}
.p-pagination span.page-numbers.dots {
  border: none;
  font-size: 16px;
}

.p-terms__inner {
  padding: 0 25vw;
}
.p-terms__title {
  font-size: 3.125vw;
  letter-spacing: 0.02em;
  line-height: 1.3;
}
.p-terms__lead {
  font-size: 27px;
  font-weight: 600;
}
.p-terms__sec {
  width: 100%;
}
.p-terms__sec-inner {
  padding: 0 40px;
}
.p-terms__sec-inner p:last-of-type {
  padding: 0;
}
.p-terms__sec-nm {
  position: absolute;
  left: -40px;
  vertical-align: top;
  top: -0.5rem;
}
.p-terms__sec-ttl {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid #d0d0d0;
  margin-left: 40px;
}
.p-terms__wrap {
  border-top: 1px solid #d0d0d0;
}
.p-terms__wrap h5 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 5px 20px;
  background: #0f3c3c;
  color: #fafafa;
  border-radius: 100px;
  display: inline-block;
}
.p-terms__wrap p {
  line-height: 1.7;
  letter-spacing: 0.05rem;
}
.p-terms__wrap a {
  color: #009b4a;
  text-decoration: underline;
  word-break: break-all;
}
.p-terms__wrap blockquote {
  background: #f4f1ee;
  padding: 35px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.05rem;
}
.p-terms__wrap ul {
  margin-bottom: 35px;
}
.p-terms__wrap ol {
  margin-left: 3rem;
}
.p-terms__wrap ol li {
  list-style: decimal;
  padding-left: 0;
  margin-bottom: 10px;
}
.p-terms__wrap ol li::before {
  content: none;
}
.p-terms__wrap ol li::marker {
  font-weight: 600;
  color: #009b4a;
}
.p-terms__wrap li {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.05rem;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 5px;
}
.p-terms__wrap li::before {
  content: "";
  background: #009b4a;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 0;
  top: 1.2rem;
  width: 4px;
  height: 4px;
}

/*--------------------------------------

  single.scss

--------------------------------------*/
.p-single h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.06rem;
  padding-bottom: 35px;
}
.p-single__nav-title-wrap {
  display: flex;
}
.p-single__article {
  padding: 0 25vw;
}
.p-single__title {
  margin-bottom: 75px;
}
.p-single__thumb {
  width: 100%;
  padding-top: 67%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.p-single__thumb span {
  width: 100%;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.p-single__share {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #d0d0d0;
  padding-top: 25px;
  margin-top: 70px;
}
.p-single__share .c-social__circle .normal {
  stroke: #a9b0b3;
}
.p-single__share .p-single__share-text {
  margin: 0 20px 0 0;
}
.p-single__icons {
  display: flex;
  width: 100px;
  justify-content: space-between;
}
.p-single__thumb-wrap {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}
.p-single__wrap {
  margin-top: 100px;
}
.p-single__wrap h2 {
  font-size: 27px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid #d0d0d0;
  color: #0f3c3c;
}
.p-single__wrap h3 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid #d0d0d0;
}
.p-single__wrap h4 {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 35px;
}
.p-single__wrap h5 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 10px 20px;
  background: #0f3c3c;
  color: #fafafa;
  border-radius: 100px;
  display: inline-block;
}
.p-single__wrap h6 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 35px;
}
.p-single__wrap p {
  font-size: 15px;
  padding-bottom: 70px;
  line-height: 1.7;
  letter-spacing: 0.05rem;
  overflow-wrap: break-word;
}
.p-single__wrap a {
  color: #009b4a;
  text-decoration: underline;
}
.p-single__wrap blockquote {
  background: #f4f1ee;
  padding: 35px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.05rem;
  margin-bottom: 70px;
}
.p-single__wrap blockquote p {
  padding-bottom: 20px;
  font-size: 15px;
}
.p-single__wrap img {
  width: 90%;
  border-radius: 20px;
  margin: 0 5% 70px 5%;
  height: 100%;
}
.p-single__wrap ol, .p-single__wrap ul {
  margin-bottom: 35px;
}
.p-single__wrap li {
  font-size: 15px;
  line-height: 1.7;
  letter-spacing: 0.05rem;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 5px;
}
.p-single__wrap li::before {
  content: "";
  background: #009b4a;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 0;
  top: 1.2rem;
  width: 4px;
  height: 4px;
}
.p-single__wrap figure {
  height: 100%;
}
.p-single__wrap h1 p, .p-single__wrap h2 p, .p-single__wrap h3 p, .p-single__wrap h4 p, .p-single__wrap h5 p, .p-single__wrap h6 p {
  padding-bottom: 0;
}
.p-single__wrap .wp-caption img {
  margin-bottom: 10px;
}
.p-single__nav {
  width: 100%;
}
.p-single__nav__article {
  align-items: flex-start;
}
.p-single__nav__info {
  display: block;
}
.p-single__nav__inner {
  width: 80vw;
  margin: 0 auto;
}
.p-single__nav-title {
  width: 20vw;
}
.p-single__nav__text {
  width: 100%;
}
.p-single__nav__body {
  width: 35vw;
}
.p-single__nav__list {
  border-bottom: 1px solid #d0d0d0;
  border-top: none;
}

@media screen and (min-width:1921px) {
  .p-fv__body, .c-sec__ttl, .c-sec__body {
    width: 1728px;
  }

  .l-footer__body, .p-allnews, .c-sub-fv__ttl, .p-news__lists, .c-col, .c-col__inner-mini {
    width: 1536px;
  }

  .p-terms__title, .c-sub-fv__ttl h3 {
    font-size: 60px;
  }

  .c-sub-fv__ttl {
    max-height: 756px;
  }

  .p-fv__body {
    margin: 0 auto;
    padding: 0;
  }

  .mv {
    left: calc((100vw - 1728px) / -2);
  }

  .p-fv__title .t1 {
    font-size: 50px;
  }

  .p-fv__title .t2 {
    font-size: 130px;
  }

  .p-fv__title .t3 {
    font-size: 130px;
  }

  .spr-w1 {
    height: 252px;
  }

  .spr-w2 {
    height: 168px;
  }

  .spr-w3 {
    height: 84px;
  }

  .spr-w4 {
    height: 42PX;
  }

  .c-sec__body, .c-sec__body, .c-sub-fv__ttl, .c-sec__ttl, .c-sec__body {
    max-width: 1728px;
  }

  .p-products__lists, .c-col {
    width: 1536px;
  }

  .fs-ll {
    font-size: 50px;
  }

  .fs-l {
    font-size: 48px;
  }

  .c-sub-fv__ttl {
    max-height: 756px;
  }

  .c-sub-fv__ttl h3 {
    font-size: 60px;
  }

  .c-col__img-big {
    width: 816px;
    padding-top: 816px;
  }

  .c-col__img-mini {
    width: 576px;
    padding-top: 384px;
  }

  .p-news__list {
    width: 480px;
  }

  .c-col__figure {
    width: 630px;
    height: 630px;
  }

  .c-sec__ttl--text {
    font-size: 50px;
  }

  .c-scroll-btn {
    width: 210px;
    height: 210px;
  }

  .c-sub-reed__inner {
    width: 714px;
    margin-left: 770px;
  }

  .c-col__inner--ask {
    width: 768px;
  }

  .c-col__inner--wide {
    width: 769px;
  }

  .c-col__img-info {
    width: 420px;
    padding-top: 420px;
  }

  .c-show__figure {
    width: 560px;
    height: 560px;
  }

  .c-show__img-body {
    width: 756px;
    height: 924px;
    border-radius: 20px;
    margin-left: -96px;
  }

  .c-show__nav-wrap {
    right: 56px;
  }

  .p-products__list {
    width: 460px;
  }

  .p-products__img {
    padding-top: 334px;
  }

  .p-products__list {
    margin-bottom: 100px;
  }

  .p-products__lists:after {
    width: 460px;
  }

  .c-info__btn-wrap .c-btn {
    width: 345px;
  }

  .c-sec__ttl--text {
    margin-left: 95px;
  }

  .c-col__img-profile {
    width: 573px;
    padding-top: 760px;
  }

  .c-col__img-job {
    width: 672px;
    padding-top: 432px;
  }

  .c-sec__ttl-contact, .u-title__jobs {
    width: 100%;
  }

  .p-allnews__thumb, .p-single__nav__thumb {
    width: 383px;
  }

  .p-allnews__thumb-inner, .p-single__nav__thumb-inner {
    width: 383px;
    padding-top: 257px;
  }

  .p-allnews__body, .p-single__nav__body {
    width: 1025px;
  }

  .p-allnews__text, .p-single__nav__text {
    width: 90%;
  }

  .p-news__list-inner {
    width: 480px;
  }

  .c-col__img-contact {
    margin-left: calc((100% - 1808px) / 2);
  }

  .p-people__slider, .p-team__slider {
    margin-left: calc(100% - 1728px);
  }

  .p-people__slider--body, .p-team__slider--body {
    padding-left: 96px;
  }

  .l-footer__left {
    width: 384px;
  }

  .l-footer__right {
    width: 1152px;
  }

  .l-footer__g-nav {
    width: 100%;
  }

  .l-footer__wrap {
    width: 45%;
  }

  .p-vision__ttl {
    font-size: 66px;
  }

  .c-figure .aw-3 {
    margin-top: -50px;
  }

  .c-col__canvas canvas {
    left: calc((100% - 1728px - 192px) / 2);
    margin-left: 0;
  }

  .c-col__canvas {
    width: 100%;
  }

  .c-col__canvas {
    margin-left: 0;
  }

  .p-vision__img {
    height: 80vh;
  }
}
@media screen and (max-width:1280px) {
  .p-allnews__article, .p-single__nav__article {
    align-items: flex-start;
  }

  .u-list {
    width: 100%;
  }

  .show-t {
    display: none;
  }

  .l-footer__o-col {
    flex-flow: wrap;
  }

  .l-footer__list {
    padding: 0 0px 20px 0px;
  }

  .p-menu__wrap {
    width: 100%;
  }

  .p-menu__nav {
    padding: 0 40px;
    width: 100%;
  }

  .p-menu__nav-primary .p-menu__nav-link {
    font-size: 35px;
  }

  .p-menu__nav {
    padding-left: 15%;
  }

  .p-menu__left {
    display: none;
  }
}
@media screen and (max-width:1024px) {
  .u-tab-col {
    display: block;
  }
  .u-tab-col .c-col__inner {
    width: 100%;
  }
  .u-tab-col .c-col__inner {
    width: 100%;
  }
  .u-tab-col .c-col__inner-lead {
    padding: 0;
  }
  .u-tab-col .c-col__inner-left {
    padding: 0;
  }
  .u-tab-col .c-col__inner-right {
    padding: 0;
  }
  .u-tab-col .c-col__text-mini {
    padding: 0;
  }
  .u-tab-col .c-col__text-right {
    margin: 0;
  }
  .u-tab-col .c-col__text-contact {
    padding: 0;
  }

  .hide-t {
    display: none;
  }

  .show-t {
    display: block;
  }

  .p-products__list {
    width: calc(50% - 20px);
  }

  .p-products__img {
    padding-top: 70%;
  }

  .p-terms__inner, .p-single__article {
    padding: 0 10vw;
  }

  .c-sub-reed__inner {
    width: 80vw;
    margin: 0 auto;
  }

  .c-sub-fv__img {
    height: 55vh;
  }

  .c-sec__ttl--text {
    font-size: 27px;
    margin-bottom: 30px;
  }

  .c-sec__ttl--text:before {
    width: 5px;
    height: 5px;
    margin-left: -10px;
  }

  .u-noline {
    border-bottom: 1px solid #e9e9e9;
  }

  .p-porker .c-col {
    align-items: flex-start;
  }

  .c-show.c-show-home__img-body {
    width: 100vw;
    margin-left: -20px;
    height: 120vw;
    border-radius: 0;
  }
  .c-show.c-show-home__figure {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    margin-top: -5vw;
  }
  .c-show.c-show-home .c-figure {
    height: 100%;
  }
  .c-show.c-show-home__nav-wrap {
    left: 0px;
    bottom: -30px;
  }
  .c-show.c-show-home__nav-body {
    display: flex;
    width: 50px;
    justify-content: space-between;
  }
  .c-show.c-show-home__indicator {
    width: 20px;
  }
  .c-show.c-show-home .u-show-mission {
    border-radius: 20px;
    height: 60vw;
    margin: 0 auto;
    overflow: hidden;
    width: 90vw;
  }

  .c-info__btn-wrap {
    display: block;
  }

  .c-info__btn-wrap .c-btn {
    width: 100%;
  }

  .c-info__btn-wrap .c-btn:first-child {
    margin-bottom: 15px;
  }

  .u-no-tab {
    align-items: flex-start;
  }

  .c-col__img-profile {
    padding-top: 50vw;
    width: 80vw;
    margin-left: -80px;
    margin: 0 auto;
  }

  .p-allnews__info, .p-single__nav__info {
    display: block;
  }

  .p-allnews__text {
    width: 100%;
  }

  .spr-w1 {
    height: 110px;
  }

  .spr-w2 {
    height: 90px;
  }

  .spr-w3 {
    height: 50px;
  }

  .spr-w4 {
    height: 30px;
  }

  .p-menu__wrap {
    width: 100%;
  }

  .p-menu__nav {
    padding: 0 40px;
    width: 100%;
  }

  .p-menu__nav-primary .p-menu__nav-link {
    font-size: 35px;
  }

  .p-menu__nav {
    padding-left: 15%;
  }

  .p-vision__coment {
    width: 70%;
  }

  .l-footer__o-col {
    flex-flow: wrap;
  }

  .l-footer__right .l-footer__g-nav {
    display: block;
  }
  .l-footer__right .l-footer__wrap {
    width: 100%;
  }
  .l-footer__right .l-footer__nav-lists {
    width: 50%;
  }

  .l-footer__list {
    padding: 0 0px 20px 0px;
  }

  .service-body .c-col {
    display: block;
  }
  .service-body .c-col__inner {
    width: 100%;
  }
  .service-body .c-col__reverse {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .c-col__figure {
    width: 60vw;
    height: 60vw;
    margin: 0 auto 5vw auto;
  }

  .home-mission .c-show__nav-wrap {
    left: 0px;
    bottom: -30px;
  }
  .home-mission .c-show__nav-body {
    display: flex;
    width: 50px;
    justify-content: space-between;
  }
  .home-mission .c-show__indicator {
    width: 20px;
  }

  .c-col__img-contact {
    width: 90vw;
  }
}
/*====================================

	mobile

====================================*/
@media screen and (max-width:680px) {
  .p-fv__title {
    bottom: 120px;
  }
  .p-fv__title .t1 {
    font-size: 6vw;
    margin-bottom: 12px;
  }
  .p-fv__title .t2, .p-fv .t3 {
    font-size: 10.5vw;
  }
  .p-fv__title .t3 {
    color: #009b4a;
  }

  .p-fv__scroll-btn {
    bottom: 20px;
  }

  .p-fv__body {
    padding: 0 20px;
  }

  .p-load_logo img {
    width: 150px;
    display: inline-block;
  }

  #sc-wrap {
    overflow: hidden;
    position: relative;
  }

  .c-sec__body {
    width: 100%;
    padding: 0 20px;
  }

  .c-sec__ttl--text {
    font-size: 27px;
    margin-bottom: 30px;
  }

  .c-sec__ttl--text:before {
    width: 5px;
    height: 5px;
    margin-left: -10px;
  }

  .c-col {
    display: block;
    padding: 0 20px;
    width: 100%;
  }

  .c-col__news {
    display: block;
  }

  .c-col__inner {
    width: 100%;
  }

  .c-col__inner-lead {
    padding: 0;
  }

  .c-col__inner-left {
    padding: 0;
  }

  .c-col__inner-right {
    padding: 0;
  }

  .c-col__inner-porker {
    margin: 0;
    padding: 0 20px;
  }

  .c-col__inner-slidenav {
    justify-content: space-between;
  }

  .c-col__img-profile {
    padding-top: 70vw;
    width: 100vw;
    margin-left: -80px;
  }

  .c-col__img-porker {
    height: 130vw;
    margin-left: -20px;
    margin-right: 0;
    overflow: hidden;
    width: 100vw;
  }

  .c-col__img-job {
    padding-top: 67%;
    width: 100%;
  }

  .c-col__img-contact {
    margin-left: -40px;
    padding-top: 78.7%;
    width: calc(100vw - 40px);
  }

  .c-col__text-mini {
    padding: 0;
  }

  .c-col__text-right {
    margin: 0;
  }

  .c-col__text-contact {
    padding: 0;
  }

  .c-col__canvas {
    height: 60vw;
    margin-left: -40px;
    width: 100vw;
  }

  .c-col__reverse {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .c-col__figure {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    margin: 0 0 0 -20px;
  }

  .c-btn__circle {
    width: 60px;
    height: 60px;
  }

  .c-btn__circl-mini {
    width: 20px;
    height: 20px;
  }

  .c-btn-company {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 5;
  }

  .c-scroll-btn {
    width: 80px;
    height: 80px;
  }

  .c-scroll-btn__arrow {
    width: 20px;
    height: 16px;
    top: -2px;
  }

  .c-figure__label-01 {
    position: absolute;
    left: 10%;
    top: 23%;
  }
  .c-figure__label-02 {
    position: absolute;
    right: -8%;
    top: 23%;
  }
  .c-figure__label-03 {
    position: absolute;
    bottom: -8%;
    left: 50%;
  }

  .c-figure__item {
    width: 30%;
    height: 30%;
  }

  .c-figure__ttl-inner {
    width: 40%;
  }

  .c-figure .aw-3 {
    margin-top: -7vw;
  }

  .p-news__lists {
    display: block;
  }

  .p-news__list object {
    display: inline-block;
    width: 100%;
    position: relative;
    width: fit-content;
  }

  .p-news__list-inner {
    width: 100%;
  }

  .c-news__list-text {
    margin-bottom: 10px;
  }

  .c-news__desc-line {
    border: none;
    margin-bottom: 0px;
    padding-bottom: 18px;
  }

  .p-news__list-inner {
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
  }

  .p-wave__text svg {
    height: 15vw;
  }

  .p-wave__icon {
    height: 19.722222vw;
    width: 19.722222vw;
  }

  .fs-ll {
    font-size: 27px;
  }

  .fs-l {
    font-size: 24px;
  }

  .fs-xm {
    font-size: 20px;
  }

  .fs-m {
    font-size: 16px;
    letter-spacing: 0.03em;
  }

  .fs-s {
    font-size: 14px;
  }

  .spr-w1 {
    height: 110px;
  }

  .spr-w2 {
    height: 90px;
  }

  .spr-w3 {
    height: 50px;
  }

  .spr-w4 {
    height: 30px;
  }

  .spr-w6 {
    height: 10px;
  }

  .show-m {
    display: block;
  }

  .hide-m {
    display: none;
  }

  .l-header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    overflow: hidden;
  }

  .l-header__body {
    padding: 8vw 0;
  }

  .l-header__logo {
    width: 150px;
  }

  .l-header__menu {
    height: 60px;
    width: 60px;
    border: 1px solid #3a5e5e;
    top: calc(8vw - 12.5px);
  }

  .p-load_c {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }

  .p-menu__wrap {
    width: 100%;
  }

  .p-menu__nav {
    padding: 0 40px;
    width: 100%;
  }

  .p-menu__nav-primary .p-menu__nav-link {
    font-size: 30px;
  }

  .p-menu__nav-list.is-navi .u-dot {
    left: -15px;
  }

  .p-menu__nav-lists {
    padding-bottom: 15px;
  }

  .p-menu__nav-secondary .p-menu__nav-list, .p-menu__nav-tertiary .p-menu__nav-list {
    padding: 0.35em 0;
  }

  .p-fv__round, .p-fv__round-body {
    height: 60px;
  }

  .p-fv__round-item {
    height: 80px;
    transform: translate(-50%, -26.666%);
    width: 120%;
  }

  .mv {
    top: -30px;
  }

  .c-news__desc-cate {
    text-align: center;
  }

  .l-footer__round-item {
    height: 200%;
  }

  .l-footer__body {
    display: block;
    padding: 0 40px;
    width: 100%;
  }

  .l-footer__left {
    width: 100%;
  }

  .l-footer__logo-img {
    width: 156px;
  }

  .l-footer__g-nav {
    flex-wrap: wrap;
  }

  .l-footer__g-nav {
    width: 100%;
  }

  .l-footer__right {
    width: 100%;
  }

  .l-footer__wrap {
    width: 100%;
  }

  .l-footer__nav-lists {
    width: 100%;
  }

  .l-footer__o-col {
    display: block;
  }

  .l-footer__col {
    display: flex;
    justify-content: flex-end;
  }

  .l-footer__list {
    padding: 0 0 0 20px;
  }

  .l-footer__bottom {
    width: calc(100vw - 40px);
    margin: 0 0 0 -20px;
  }

  .l-footer__o-nav {
    width: calc(100vw - 40px);
    margin: 0 0 0 -20px;
  }

  .u-noline {
    border-bottom: 1px solid #e9e9e9;
  }

  .u-ttl__mini {
    top: 10%;
  }

  .c-sub-fv {
    position: relative;
    z-index: 8;
  }

  .c-sub-fv__ttl h3 {
    font-size: 8.2vw;
    line-height: 1.4;
    width: calc(100% + 30px);
  }

  .c-sub-fv__ttl {
    height: auto;
    padding-bottom: 100px;
  }

  .c-sub-fv__ttl-small {
    padding-bottom: 0px;
  }

  .c-sub-fv__img {
    height: 95vw;
    width: 95%;
  }

  .c-sub-fv__img div {
    background-position: center;
  }

  .c-sub-reed__inner {
    margin-left: 0;
    width: 100%;
    padding: 0 20px;
  }

  .js-ttl {
    line-height: 1.1;
  }

  .c-dropdown {
    border-radius: 25px;
    width: 60%;
  }

  .c-ask__nm {
    width: 30px;
  }

  .c-ask__text {
    width: calc(100% - 60px);
  }

  .c-ask__q {
    width: calc(100% - 35px);
  }

  .c-ask__jp {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.01em;
  }

  .c-ask__btn {
    margin-top: 2rem;
  }

  .c-ask__body--text {
    margin-top: 30px;
    padding: 30px;
  }

  .c-ask__title {
    align-items: flex-end;
  }

  .c-btn-acc {
    bottom: 1.5rem;
  }

  .c-ask:first-child .c-btn-acc {
    bottom: 0;
  }

  .c-col__inner--info {
    margin: 0;
  }

  .c-col__img-info {
    width: 100%;
    padding-top: 100%;
  }

  .p-vision__ttl {
    font-size: 8vw;
    width: 100%;
  }

  .p-vision__texts {
    color: #222;
  }

  .p-vision__img-bg {
    position: relative;
    height: 35vh;
  }

  .p-vision__img-item {
    background-position: right;
  }

  .p-vision__img-bg {
    width: 100%;
    margin-left: 0vw;
    border-radius: 0px;
  }

  .p-vision__img-item {
    margin-left: 0vw;
  }

  .c-show__img-body {
    width: 100vw;
    margin-left: -20px;
    height: 130vw;
    border-radius: 0;
  }
  .c-show__figure {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    margin-top: -5vw;
  }
  .c-show .c-figure {
    height: 100%;
  }
  .c-show__nav-wrap {
    left: 0px;
    bottom: -30px;
  }
  .c-show__nav-body {
    display: flex;
    width: 50px;
    justify-content: space-between;
  }
  .c-show__indicator {
    width: 20px;
  }
  .c-show .u-show-mission {
    height: 70vw;
    width: calc(100vw - 40px);
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
  }

  .c-figure__ai {
    transition-delay: 0.1s;
  }
  .c-figure__ict {
    transition-delay: 0.2s;
  }
  .c-figure__iot {
    transition-delay: 0.3s;
  }
  .c-figure__label {
    transition-delay: 0.1s;
  }

  .c-col__canvas canvas {
    margin: 0 auto;
  }

  .p-products__list {
    width: 100%;
    margin-bottom: 60px;
  }

  .p-products__img {
    padding-top: 71.6%;
  }

  .c-info__btn-wrap {
    display: block;
  }

  .c-info__btn-wrap .c-btn {
    width: 100%;
  }

  .c-info__btn-wrap .c-btn:first-child {
    margin-bottom: 15px;
  }

  .p-team__slider .c-btn-acc {
    top: 0;
  }

  .p-team__slider--body, .p-people__slider--body {
    width: calc(100vw - 60px);
  }

  .p-team__slide-current, .p-people__slide-current {
    width: 20vw;
    height: 2px;
  }

  .p-team__slide--text {
    padding-bottom: 35px;
  }

  .p-company__wrap {
    height: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0;
  }

  .p-explore .p-allnews__article, .p-explore .p-explore__link {
    display: block;
  }
  .p-explore .p-allnews__body, .p-explore .p-allnews__thumb, .p-explore .p-allnews__text, .p-explore .p-explore__link-wrap {
    width: 100%;
  }
  .p-explore .p-allnews__info {
    display: block;
  }
  .p-explore .c-btn_body {
    width: 100%;
    margin: 0;
  }
  .p-explore .p-explore__link-item:nth-child(even) {
    margin-left: 10px;
  }
  .p-explore .p-explore__link-item:nth-child(odd) {
    margin-right: 10px;
  }
  .p-explore .p-explore__link-item {
    width: calc(50% - 10px);
  }

  .p-jobs__body {
    width: calc(100% - 40px);
    margin: 0 20px;
  }

  .p-jobs .c-col {
    padding: 0;
  }

  .c-col__text-job {
    margin: 0;
  }

  .p-allnews__thumb {
    width: 90%;
    display: flex;
    align-items: center;
  }

  .p-allnews__thumb-explore {
    height: 100%;
    display: block;
  }

  .p-allnews_thumb-wrap, .p-allnews__info, .p-allnews__article {
    display: block;
  }

  .p-allnews__body, .p-allnews__info, .p-allnews__text {
    width: 100%;
  }

  .p-allnews_thumb-wrap {
    width: 50%;
  }

  .p-allnews .c-news__desc {
    padding-left: 20px;
  }
  .p-allnews .c-news__desc-data {
    padding-bottom: 10px;
  }
  .p-allnews__article .show-m {
    display: block;
  }

  .p-single__nav__article .show-m, .p-allnews__article .show-m {
    display: block;
  }

  .c-dropdown-list, .c-dropdown__title {
    padding: 10px 30px;
  }

  .is-active {
    height: var(--h);
    position: relative;
    width: 100%;
    overflow: inherit;
    transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 1;
  }

  .is-off {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .p-single__article {
    padding: 0 40px;
  }

  .p-single__title {
    margin-bottom: 60px;
  }

  .p-single__share {
    margin-top: 20px;
    justify-content: space-between;
  }

  .p-single h1 {
    font-size: 30px;
    padding-bottom: 35px;
  }

  .p-single__nav__body {
    width: 50%;
  }

  .p-single__nav__thumb {
    width: calc(50% - 40px);
    margin-left: 0;
  }

  .p-single__nav__list:first-child {
    border-top: 1px solid #d0d0d0;
  }

  .p-single .p-allnews__thumb-wrap, .p-single .p-single__nav__thumb-wrap {
    border-radius: 5px;
  }

  .p-allnews__thumb-wrap, .p-single__nav__thumb-wrap {
    border-radius: 10px;
  }

  .p-single .p-single__nav__body {
    width: 55%;
  }
  .p-single .p-single__nav__body .spr-w5 {
    height: 10px;
  }
  .p-single .p-single__nav__body .c-news__desc {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .p-single__nav__text.fs-m {
    font-size: 15px;
    line-height: 1.6;
  }

  .p-single__wrap {
    margin-top: 60px;
  }
  .p-single__wrap h2 {
    font-size: 24px;
    line-height: 1.5;
  }
  .p-single__wrap h3 {
    font-size: 21px;
    line-height: 1.6;
  }
  .p-single__wrap h4 {
    font-size: 17px;
    line-height: 1.6;
  }
  .p-single__wrap h5 {
    font-size: 15px;
    line-height: 1.6;
    padding: 10px 30px;
  }
  .p-single__wrap h6 {
    font-size: 16px;
    line-height: 1.8;
  }
  .p-single__wrap blockquote {
    padding: 40px;
  }
  .p-single__wrap img {
    width: 90%;
    border-radius: 20px;
    margin: 0 5% 70px 5%;
  }
  .p-single__wrap ul {
    margin-bottom: 35px;
  }
  .p-single__wrap li {
    font-size: 17px;
    line-height: 1.7;
    letter-spacing: 0.05rem;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 5px;
  }
  .p-single__wrap li::before {
    content: "";
    background: #009b4a;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 0;
    top: 1.2rem;
    width: 4px;
    height: 4px;
  }
  .p-single__wrap p {
    font-size: 16px;
  }

  .p-single__nav__article {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .p-single__nav-title {
    width: 100%;
    padding-bottom: 20px;
  }

  .p-terms__inner {
    padding: 0 40px;
  }
  .p-terms__title {
    font-size: 8.5vw;
    line-height: 1.4;
  }
  .p-terms__sec {
    width: 100%;
  }
  .p-terms__sec-inner {
    padding: 0 0;
  }
  .p-terms__sec-nm {
    left: 0px;
    top: 0rem;
    font-size: 12px;
  }
  .p-terms__sec-ttl {
    padding-left: 30px;
    margin-left: 0px;
    line-height: 1.5;
  }
  .p-terms__wrap {
    border-top: 1px solid #d0d0d0;
  }
  .p-terms__wrap blockquote {
    border-radius: 10px;
  }
  .p-terms__wrap ul {
    margin-bottom: 35px;
  }
  .p-terms__wrap ol {
    margin-left: 3rem;
  }
  .p-terms__wrap ol li {
    list-style: decimal;
    padding-left: 0;
  }
  .p-terms__wrap ol li::before {
    content: none;
  }
  .p-terms__wrap ol li::marker {
    font-weight: 600;
    color: #009b4a;
  }
  .p-terms__wrap li {
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 0.05rem;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 5px;
  }
  .p-terms__wrap li::before {
    content: "";
    background: #009b4a;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 0;
    top: 1.2rem;
    width: 4px;
    height: 4px;
  }

  .p-load__inner, .p-menu__bg, .l-load__inner {
    transform: scaleX(1.6);
  }

  .p-vision__coment {
    width: 100%;
    /* position: absolute;    bottom: 10%;    margin: 0;*/
  }

  .p-vision__img-item {
    opacity: 1;
    transform: scale(1);
  }

  .p-load__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .p-load_logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .p-explore .c-news__desc {
    padding: 0;
  }

  .p-pagination .page-numbers, .p-pagination__page {
    display: none;
  }

  .p-pagination__inner {
    align-items: center;
  }
}