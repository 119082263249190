/*--------------------------------------

  page.scss

--------------------------------------*/

//mission
.c-sub-fv {
  display: block; height: 100%; width: 100%;
  &__ttl    { width: 80vw; margin: 0 auto; position: relative; height: 45vw;  z-index: 2;}
  &__ttl-small{height: auto;}
  &__ttl h2 { color: $base-color; font-size: 18px; }
  &__ttl h3 { font-size: vw(55px);  letter-spacing: 0.02em;line-height: 1.3;}
  &__img    { overflow: hidden; position: relative; width: 95%; height: 105vh; margin: 0 auto; z-index: 1; border-radius: 20px; background: $deep-bg;}
  &__img img{ border-radius: 20px; }
  &__scroll-btn { position: absolute; bottom: -5vw; right: 0; }
}
.c-sub-reed__inner { margin-left: 40vw; width: 42.5vw; }
.p-vision{
  position: relative;
  &__img    { overflow: hidden; position: relative; width: 100%; height: 100%; margin: 0 auto; z-index: 2;}
  &__img-bg{    border-radius: 0px;    width: 90%;    height: 100%;    display: block; margin-left: 5vw; border-radius: 20px;   position: absolute;top: 0; overflow: hidden; z-index: 3;}
  &__img-inner{width: 90%; margin: 0 auto; position: relative; height: 100%;}
  &__img-item{ transform: scale(1);z-index: 2; width: 100vw; margin-left:-5vw;  border-radius: 0px; position: absolute; }
  &__texts{z-index: 5;  color: $white-color; width: 80vw;  margin: 0 5vw;}
  &__texts{z-index: 5;  color: $white-color;}
  &__ttl{font-size: vw(50px);}
  &__ttl span{display: block; line-height: 1.3;}
  &__coment{width: 45%;}
}


.p-products{ background-color: $deep-bg;}

.p-products {
  background-color: $beige-bg;
  &__list-inner { width: 25vw; display: inline-block; cursor: pointer;  }
  &__lists { flex-wrap: wrap; align-items: flex-start; display: flex; margin: 0 auto; width: 80vw; justify-content: space-between;  }
  &__lists::after{  content:"";  display: block;  width:25vw}
  &__list  { width: 25vw; position: relative; margin-bottom: 7.5vw;}
  &__img{ padding-top: 17.5vw; width: 100%; overflow: hidden; border-radius: 20px; position: relative;z-index: 5;}
  &__img img{transform:  scale(1.05)}
  &__text{padding-right: 10px; line-height: 1.8;}
  small{margin-top: 10px; display: block; font-size: 12px;opacity: 0.8;line-height: 1.8;}
}


//company
/*----------------------------------
  keenslider
----------------------------------*/
.p-team,.p-people{
  width: 100vw;
  overflow: hidden;
  &__slider{width: 100vw ; margin-left: -5vw;}
  &__slider--body { width: 80vw;  position: relative;  padding-left:10vw; overflow: visible; display: flex;  -webkit-user-select: none;  -moz-user-select: none;  -ms-user-select: none;  user-select: none;  -webkit-touch-callout: none;  -khtml-user-select: none;  touch-action: pan-y;  -webkit-tap-highlight-color: transparent;  }
  &__slider--body{ &.is-drag{ .p-team__slide--img,.p-people__slide--img{cursor: grabbing;}}}
  &__slide { position: relative;   width: 100%; min-height: 100%;  padding-right: 5vw; height: 100%;}
  &__slide { min-height:100%;overflow:hidden;position:relative;width:100%}
  &__slide--inner{ width:100%; height:auto;  border-bottom: 1px solid $gray-color;}
  .p-people__slide--inner{border-bottom: none;}
  
  &__slide--img{ position: relative; padding-top: 130%;width: 100%; overflow: hidden; border-radius: 20px; z-index: 5; cursor: grab;background: $deep-bg;}
  &__slide--name { align-items: center; cursor: pointer;    display: flex;  align-items: center;  justify-content: space-between;}

  &__slide--text{ padding-bottom: 2.5vw;}
  &__slide--profile{ transition: 1s;}
  &__slide-nav{ display: flex; justify-content: space-between; }
  &__slide-nav div{ margin: 0 5px;}
  &__slide-current{ width: 10vw; background:$gray-color; margin-right: 20px; position: relative; height: 1px; overflow: hidden;}
  &__slide--desc{
    &.is-active{
      .c-btn-acc{ background-color: $deep-color; transform:  rotate(90deg); transition: .5s ;}
    }
  }


}
.u-slide__current{    content: "";    width: 0%;    height: 100%;    background: $base-color;    position: absolute;    display: inline-block;}
.p-company{
  &__list{padding-bottom: 25px;}
  &__list h5{    padding-bottom: 10px;  }
}
.slide-img{width: 130%;left: -15%;}


//careers
.p-jobs{
  &__body{ background: $deep-bg;   border-radius:20px;}
}
.p-explore {
  background: $deep-bg; 
  &__link{display: flex;justify-content: space-between;}
  &__link h4{ opacity: .5;}
  &__link-wrap{ display: flex;flex-wrap: wrap; width: 50vw;}
  &__link-item{width: 50%;}
  &__link-item .c-btn_body{display: flex; justify-content: space-between; border-bottom: 1px solid $gray-color;     width: 20vw; margin-left: 5vw; padding: 25px 0;}
  .p-allnews__list{border-bottom: 1px solid #d0d0d0; border-top: none;}
}


//news
.c-dropdown {
	background: $deep-color;  display: inline-block;	position: absolute;top: 0;	overflow: hidden;	border-radius: 30px;  margin: 0 0 0 auto;  cursor: pointer;z-index: 5; min-width: 15vw;
  &-list{padding: 15px 30px; width: 100%; height: 100%; transition:background-color .2s;}
  &-list:hover{background: #154a4a; transition:background-color .2s;}
  &-list{a{width: 100%; height: 100%;display: block;}}
  &__title{padding: 15px 30px; display: flex; align-items: center; justify-content: space-between;}
  .triangle{width: 0;height: 0;border-style: solid;border-width: 6px 4px 0 4px; border-color: #9fb1b1 transparent transparent transparent;}
}

//all news
.p-single__nav,
.p-allnews{
  width: 80vw;  margin: 0 auto;
  &__article{display: flex; align-items: center; position: relative;}
  &__list{ border-top: 1px solid $gray-color; }
  &__info{    display: flex;    align-items: flex-end; width: 100%;justify-content: space-between;}
  &__thumb-wrap{ display: inline-block ; width: 100%; height: 100%; overflow: hidden; border-radius: 20px; z-index:5; position: relative;}
  &__thumb{width: 20vw;margin-right:5vw ; }
  &__thumb-inner{width: 100%;padding-top: 67.2%; position: relative; overflow: hidden; transition:transform 1s;border-radius: 0px; z-index: 5;}
  &__body {width: 55vw;z-index: 2;}
  &__text{width: 45vw;}
  &__article-link{width: 100%;height: 100%;z-index: 0;position: absolute;top: 0;left: 0;}
  &__article .show-m{display: none;}
}
.p-pagination{
  border-top: 1px solid $gray-color;  position: relative;
  &__inner{    display: flex;    justify-content: center;  }
  a.prev.page-numbers {display: inline-block;position: absolute; left : 15px;  border: 0;}
  .page-numbers span{line-height: 34px;}
  a.next.page-numbers {display: inline-block;position: absolute; right: 0; border: 0;}
  .p-pagination__prev.c-btn { justify-content: flex-start; margin-left: -15px; }
  a.prev.page-numbers .c-btn__text { padding-left: 15px; }
  &__page,.page-numbers { display: inline-block; width: 36px; height: 36px; border-radius: 100%;  text-align:center; line-height: 36px;margin: 0 0.5rem; border: 1px solid  #5c7170;} 
  &__acthive ,.current {background: $base-color;  border: 1px solid $base-color ;}
  &__acthive ,.current span{color: $white-color;}
  span.page-numbers.dots { border: none; font-size: 16px;}
}

//規約
.p-terms{
  &__inner  { padding: 0 25vw; }
  &__title  { font-size: vw(45px);  letter-spacing: 0.02em;line-height: 1.3;}
  &__lead   { font-size: 27px; font-weight: 600; }
  
  &__sec      { width: 100%;}
  &__sec-inner{ padding:0 40px;p:last-of-type { padding: 0;}  }
  &__sec-nm   { position: absolute; left: -40px; vertical-align: top; top: -0.5rem;}
  &__sec-ttl  { position: relative; font-size: 24px; font-weight: 600; padding-bottom: 30px; margin-bottom: 35px; border-bottom:1px solid $gray-color; margin-left: 40px;}
  &__wrap{
      border-top: 1px solid $gray-color;  
      h5{ font-size: 15px; font-weight: 600; margin-bottom: 20px; padding: 5px 20px;  background : $deep-color; color: $white-color; border-radius: 100px; display: inline-block;}
      p { line-height: 1.7; letter-spacing: 0.05rem;}
      a { color: $base-color; text-decoration: underline; word-break: break-all;}
      blockquote{background: $deep-bg;padding: 35px; border-radius: 20px; font-size: 14px; line-height: 1.7; letter-spacing: 0.05rem;}
      ul{ margin-bottom: 35px;}
      ol{
        margin-left: 3rem;
        li{list-style: decimal;padding-left:0; margin-bottom: 10px;}
        li::before{content:none;}
        li::marker{ font-weight: 600; color: $base-color;}
      }
      li{ font-size: 14px; line-height: 1.7; letter-spacing: 0.05rem;position: relative; padding-left: 1.5rem;  margin-bottom: 5px;}
      li::before{content: ""; background: $base-color; border-radius: 50%; display: block; position: absolute; left: 0; top: 1.2rem; width: 4px; height: 4px;}
    }
}