/*----------------------------------
	
  Base
  
----------------------------------*/

/* 1 */
/*1-1*/ @use 'sass:math';
/*1-2*/ @function strip-unit($number)   { @if type-of($number) == 'number' and not unitless($number) { @return math.div($number, $number * 0 + 1); } @return $number; }
/*1-3*/ @function rem($px, $base: 16px) { $value: $px; @if (unit($px) != 'px') { @warn 'rem()の引数にpx以外の値を指定しても計算できません'; @return $value; } $value: math.div(strip-unit($px), strip-unit($base)) * 1rem; @return $value; }
/*1-4*/ @function vw($px, $vw: 1440)    { $value: $px; @if (unit($px) != 'px') { @warn 'vw()の引数にpx以外の値を指定しても計算できません' ; @return $value; } $value: math.div(1, strip-unit($vw)) * 100 * strip-unit($px) + vw; @return $value; }

/*----------------------------------
	
  変数
  
----------------------------------*/

/*color*/
$base-bg: #faf9f7;
$deep-bg: #f4f1ee;

$base-color: #009b4a;
$deep-color: #0f3c3c;
$gray-color: #d0d0d0;
$white-color:#fafafa;

$beige-bg: #f4f2ee;
$font-black: #333;


/*ease*/
$ease : transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
$easeIn : transform 0.55s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.55s cubic-bezier(0.165, 0.84, 0.44, 1);


/*フォントサイズ*/
.fs-ll {  font-size: vw(43px);  letter-spacing: 0.03em;  font-weight: 600;  line-height: 1.4;}
.fs-l  {  font-size: vw(37px);  letter-spacing: -0.008em;  font-weight: 600;  line-height: 1.5;}
.fs-xm {  font-size: 24px;  font-weight: 600;}
.fs-m  {  font-size: 17px;  line-height: 1.8;  letter-spacing: 0.05em; }
.fs-s  {  font-size: 14px;  line-height: 1.7;  letter-spacing: 0.05em;}
.fs-xs {  font-size: 12px;  line-height: 1.6;  letter-spacing: 0.05em;}

/*フォントウェイト*/
.fw-m  {  font-weight: 600;}
.android {
  .c-title {
    font-weight: 700;
  }
  .fw-m  {  font-weight: 900;}
}

/* スペース */
.spr {
  width: 100%;
  &-w1 {    height: 15vw;  }
  &-w2 {    height: 10vw;  }
  &-w3 {    height: 5vw;   }
  &-w4 {    height: 2.5vw; }
  &-w5 {    height: 25px; }
  &-w6 {    height: 15px; }
}

/* 2 */
// @import './yakuhanjp/yakuhanjp.scss';

html {  width: 100%;  height: 100%;  font-size: 62.5%;}
body {  width: 100%; position: relative;  overflow-x: hidden;  scroll-behavior: smooth;  overscroll-behavior-y: none;  font-feature-settings: 'palt'; /*自動行間制御*/  -webkit-font-smoothing: antialiased; /*アンチエイリアス*/  overscroll-behavior: none;  margin: 0;  padding: 0;  overflow-x: hidden;  background-color: #f4f1ef;  color: $font-black;  font-family: 'Montserrat', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'Yu Gothic', sans-serif, sans-serif;  font-weight: 500;}
main { position: relative;}
.img {  width: 100%;  height: 100%;}
.show-m{ display: none;}
.hide-m{ display: block;}
br{line-height: 0;}

/*----------------------------------
	
    3.Brakepoints

----------------------------------*/
$breakpoints: (
  'mobile': 'screen and (max-width:680px)',
  'tablet': 'screen and (max-width:1024px)',
  'tablet-large': 'screen and (max-width:1280px)',
  'desktop': 'screen and (min-width:1921px)',
);
@mixin bp($breakpoint: desktop) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}


