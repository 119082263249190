/*--------------------------------------

  home.scss

--------------------------------------*/
.p-fv {
  display: block; width: 100%;  height: 100%; overflow: hidden;  position: relative;  background: $deep-bg;
  &__body         { padding: 0 10vw; position: relative; height: 100vh; z-index: 2; top:0; }
  &__title        { position: absolute; bottom: 5vw;  color: $deep-color;}
  &__title  span  { display: block; font-weight: 600; }
  &__title .t1    { font-size: vw(40px);margin-bottom: 1.6vw;}
  &__title .t2,.t3{ font-size: vw(100px); }
  &__round        { height: 100px; background: $base-bg; margin-top: -5px; position: relative; display: block; z-index: 5;}
  &__round-body   { width: 100%; top: 0; position: relative; height: 10vh; overflow: hidden; height: 70px; background: $base-bg; }
  &__round-item   { width: 150%; content: ''; display: block; position: absolute; background: $deep-bg; height: 750%; left: 50%; border-radius: 50%; transform: translate(-50%, -86.666%); z-index: 1; }
  &__scroll-btn   { position: absolute; bottom: 5vw; right: 0; }
  &__o            { display: block; overflow: hidden;}
}

.service {  &-body { background: $deep-color; color: $white-color; }}
.mv{bottom:0;left:0;z-index:-1;width:50vw;pointer-events:none;background:$deep-bg;position:absolute;top:0;left:0;}
.space{top:3vw;background:$base-bg;z-index:10;height:50vh}


//告知用
// #__bs_notify__{display: none !important;}
// body{
//   cursor: none;
// }