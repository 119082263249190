/*--------------------------------------

  compornant

--------------------------------------*/
/* body */
.c-inner {  display: inline-block;  width: 100%;  height: 100%;  position: relative;}

/* section */
.c-sec__body { width: 90vw; margin: 0 auto;  }
.c-sec__ttl {
  position: relative;  width: 90vw;  margin: 0 auto;  border-bottom: 1px solid $gray-color;  color: $deep-color;
  &--text { display: block; margin-left: 5vw; margin-bottom: 40px; font-size: vw(40px); font-weight: 600; position: relative; }
  &--text::before { content: ''; display: block; width: 8px; height: 8px; border-radius: 100%; background: $base-color; position: relative; position: absolute; left: -2.5vw; top: 0; right: auto; bottom: 0; margin: auto;    }
  &-contact{width: 100%; }
  &-contact &--text{font-size: 24px;}
}

/* colom */
.c-col {
  display: flex;  width: 80vw;margin: 0 auto;  align-items: center;
  &__contact,&__profile,&__news{ align-items: flex-start;}
  &__news{display: flex;}

  &.c-col__top { align-items: flex-start;  }
  &__inner { width: 50%; height: 100%; position: relative;  }
  &__inner--ask { border-bottom: none; margin-left: -5vw; width: 40vw;  }
  &__inner--wide { width: 60%;  }
  &__inner-lead { padding-right: 10vw;  }
  &__inner-left { padding-right: 5vw;  }
  .u-col__careers{width: 80%;}
  &__inner-right { padding-left: 5vw;  }
  &__inner--info {width: 55%; margin-left: -5vw;}
  &__inner-porker { width: 45%; margin-left: 5vw;}
  &__inner-mini{ width: 80vw; margin: 0 auto;}
  &__inner-news{display: flex; justify-content: flex-end; align-items: center; position: relative; z-index: 5;}

  &__inner-slidenav{display: flex; justify-content: flex-end; align-items: center;}

  &__text-mini { padding: 0 2.5vw 0 5vw;  }
  &__text-right { margin-right: 2.5vw;  }
  &__text-job { margin:0 5vw;  }
  &__text-contact { padding-left:5vw;  padding-right: 1.8vw;}
  &__text--info {margin-right: 5vw;}
  &__img { position: relative; border-radius: 20px; overflow: hidden;  z-index: 5;}
  &__logo{ margin: 0 10vw; }
  &__canvas { width: 45vw; height: 35vw;  margin-left: -5vw; display: flex;    align-items: center;  }
  &__canvas canvas {margin-left: -5vw; margin-top: -5vw; position: absolute; }
  &__img-big { width: 42.5vw; margin-right: -2.5vw; padding-top: 42.5vw; }

  &__img-mini { width: 30vw; margin-right: -2.5vw;  padding-top: 20vw;}
  &__img-job { width: 35vw; margin-right: 0vw; margin-left: auto;  padding-top: 22.5vw; background-color:$deep-color;}

  &__img-info { width: 25vw; padding-top: 25vw;}
  &__img-profile{width: 30vw; padding-top: 45vw;}
  &__img-contact{width: 40vw; margin-left: -10vw; padding-top: 60vw; border-radius: 0 20px 20px 0;}
  // &__img-porker { width: 45vw; height: 55vw; margin-left: -10vw; overflow: hidden; margin-right: 5vw;}
  &__figure { width: 37.5vw; height: 37.5vw; text-align: right; margin-left: auto; margin-right: -2.5vw;  }
}
.c-figure{
  display: flex; justify-content: center; align-items: center; position: relative;
  &__base       { transform: translate(-50%, -50%) rotate(-90deg) scale(1 , -1); }
  &__base svg{animation: r360-r 40s infinite linear;}
  &__base-cover { fill: transparent; stroke: $deep-color; stroke-width: 5;}
  &__base-cover { stroke-dasharray: 366 366; stroke-dashoffset: 0; }
  &__base-dash  { fill:transparent;stroke:#fff;stroke-width:.6;stroke-dasharray:0 1.7;stroke-linecap:round;opacity:.6 }
  &__base       { bottom: 50%;height: 80%;left: 50%;position: absolute;right: 50%;top: 55%; width: 80%;}
  @keyframes circle { 0% { stroke-dasharray: 366 366; } 99.9%,to { stroke-dasharray: 0 366; }}

  &__item {     opacity:0;  background: #fafafa;    border-radius: 100%;    height: 34%;    width: 34%; position: relative; display: flex; justify-content: center; align-items: center; }
  &__ai    {    position: absolute;    top: 22%;      left: 50%;    transform: translate(-50%, -50%);   transition: .8s;  transition-delay: .4s; }
  &__ict   {    position: absolute;    bottom: 6%;    left: 3%;                                        transition: .8s;  transition-delay: .5s; }
  &__iot   {    position: absolute;    bottom: 6%;    right: 3%;                                       transition: .8s;  transition-delay: .6s; }

  &__label-wrap {    width: 100%;height: 100%;position: relative; }
  &__label { padding: 2px 10px; background: $base-color; border-radius: 100vw; text-align: center; opacity: 0; transition: .8s;transition-delay: .6s;}
  &__label-01{ position: absolute; left: 5%;  top:38%; transform: translate(-50%, -50%);  } 
  &__label-02{ position: absolute; right: -5%; top:38%;  transform: translate(-50%, -50%);  }
  &__label-03{ position: absolute; bottom: -5%; left: 50%; transform: translate(-50%, -50%);  }

  &__ttl-wrap{ width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; position: absolute; }
  &__ttl-inner{ width: 35%;text-align: center; opacity: 0;}
  &__logo{   width: 90%;    display: flex;    margin: 0 auto;     padding: 7% 0 0 0; }
  &__logo svg{   width: 100%; height: 100%;}

  &__arrow-wrap{ animation: r360 7s infinite linear;   display: flex; justify-content: center; width: 80%; height: 80%; position: absolute; top:15%; opacity: 0;}
  &__arrow { width: 3%; height: auto;}
  .aw-2{    transform: rotate(120deg);  margin-top: 0vw;}
  .aw-3{    transform: rotate(236deg);  margin-top: -3vw;}
  // @keyframes r360 { 0% { transform: rotate(0deg); } 99.9%,to { transform: rotate(360deg);}}
  @keyframes r360 { 0% { transform: rotate(20deg); } 99.9%,to { transform: rotate(110deg);}}
  @keyframes r360-r { 0% { transform: rotate(0deg); } 99.9%,to { transform: rotate(-360deg);}}

  &.is-on{ 
    .c-figure__base-cover { 
      animation: circle 3s forwards;
    }
    .c-figure__label,.c-figure__item{ opacity: 1;}
    .c-figure__ai    {  top: 20%;}
    .c-figure__ict   { bottom: 8%;}
    .c-figure__iot   { bottom: 8%;}
    .c-figure__arrow-wrap{ opacity: 1; transition: .8s ;}
    .c-figure__ttl-inner{ opacity: 1; transition: .4s ;}
  } 

}

/* news */
.c-news {
  &__desc { display: flex; align-items: center;  }
  // &__desc-line { padding-bottom: 22.5px; margin-bottom: 22.5px; border-bottom: 1px solid $gray-color;  }
  &__desc-line { margin-bottom: 18px;padding-bottom: 18px; border-bottom: 1px solid #e4e4e4;  }
      
  &__desc-data { font-size: 14px; margin-right: 20px;  opacity: 0.8;}
  &__desc-cate { font-size: 12px; font-weight: 600; padding: 2px 6px; color: $base-color; border: 1px solid $base-color; border-radius: 100px;  z-index: 5;}
  &__list-text { margin-bottom: 30px;  }
  &__list-link { color: $deep-color;  }

  &__desc-site{ color: $deep-color;}
  
}

/* btn */
/* 通常リンク */
.c-btn {
  width: 100%;  height: 100%;  display: flex;  justify-content: flex-end; align-items: center;
  &_body { display: flex; align-items: center;}
  &__text { margin-right: 2rem;color: $deep-color;}
  &__circle { display: inline-block;width: 70px; height: 70px; background: $base-color; border-radius: 100%;  display: flex; justify-content: center;align-items: center; transform-origin: center; position: relative; overflow: hidden;}
  &__circle-arrow{ width: 20px; height: 16px; position: relative;  display: inline-block; z-index: 3;}
  &__circle-arrow svg{	position: absolute;top: 0;left: 0;}
  &__circl-mini { display: inline-block; width: 20px; height: 20px; display: flex; justify-content: center;align-items: center; }
  &__circle-dl {transform: rotate(90deg); margin-top: -2px;}
  &__circle-dl::after { border-right: 1px solid #fff;    content: "";    width: 110%;    height: 100%;    display: block;    bottom: 0;    position: absolute;margin: 0;}
  &__circle-arrow-mini{ width: 10px; height: 8px; position: relative;  }
  &__circle-arrow-top{ width: 8px; height: 10px; position: relative;  }
  &__circle-arrow-mini svg{	position: absolute;top: 0;left: 0;}
  // &-news{ position: absolute; right: 5vw; top: 0; top: 20%;    align-items: flex-start;  }
  &_left{ justify-content: flex-start;}

  &-acc{ display: inline-block; width: 35px; height: 35px; background: $base-color; cursor: pointer; border-radius: 100%; position: relative; transition: .5s ;}
  &-slide{cursor: pointer;}
  &-slide {&.is-navi{cursor: default; opacity: .5;}}
  &__dots{ width: 100%;z-index: 20;}
  &__dot{position: absolute;top: 50%;    left: 50%;    transform: translate(-50%, -50%);    transition: 0.2s;border-radius: 50%;    display: inline-block;    height: 3px;    width: 3px;    background: #fff;}
  &__dot:nth-child(1){left: 12px;}
  &__dot:nth-child(3){left: 23px;}

  &__circle-cover{width: 100%; height: 100%; position: absolute; top: 0; left: 0; transform: scale(0,0);border-radius: 100%; }
}
/* scroll */
.c-scroll-btn {  
  background-color: #009b4a;  border-radius: 100%;  display: flex;  justify-content: center;  align-items: center;  height: 10vw;  width: 10vw;cursor: pointer;
  &__arrow{ width: 20px; height: 24px; position: relative;  }
  &__arrow svg{	position: absolute;top: 0;left: 0;}
}



/* c-ask */
.c-ask {
  border-bottom: 1px solid #dcdcdc;  padding-bottom: 35px;  margin-bottom: 35px;
  &__q { display: flex;  }
  &__nm { width: 5vw; font-weight: 600;  }
  &__en { font-weight: 600; padding-bottom: 10px; opacity: 0.5;  }
  &__title { display: flex; justify-content: space-between; align-items: center; cursor: pointer;  }
  &__text { span { display: block; }  }
  &__text { h4 { display: block; }  }
  &__body--text{background:$deep-bg; padding:5vw; margin-top: 2.5vw; border-radius: 20px;}
  &__title{
    &.is-active{
      .c-btn-acc{ background-color: $deep-color; transform:  rotate(90deg); transition: .5s ;}
    }
  }
}


/*　social　*/
.c-social {
  &__link {   opacity: 1; position: relative;    display: flex;    justify-content: center;    align-items: center;    width: 40px;    height: 40px;    border-radius: 100%;    overflow: hidden;    cursor: pointer;    transition: 0.5s opacity;  }
  &__link :hover .normal { stroke-width: 0; transition: 0.5s; stroke-dasharray: 130; stroke-dashoffset: 130; }
  &__circle { position: absolute; top: 0; left: 0; width: 100%; height: 100%;  transform: rotate(-85deg);}
  &__circle .normal{ stroke: #275050; stroke-width: 1px; transition: 0.5s; stroke-dasharray: 130; stroke-dashoffset: 0; }
  &__circle .hover{ transition: 0.5s; }
  &__circle svg{ transition: 0.5s; }
  &__icon { width: 15px; height: 100%; max-height: 15px; display: inline-block; opacity: 1;  }
}
/* news */
.p-news {
  background-color: $beige-bg;
  &__list-inner { width: 25vw; display: inline-block; cursor: pointer;  }
  &__lists { align-items: flex-start; display: flex; margin: 0 auto; width: 80vw; justify-content: space-between;  }
}
.p-wave {
  width: 100vw;  overflow: hidden;
  &__body { white-space: nowrap; left: 0;  }
  &__inner { display: inline-block; animation: 35s wave infinite linear;}
  &__item { display: inline-block; color: #dcdcdc; font-weight: 600; margin: 0 1.5vw; letter-spacing: 0.5vw; vertical-align: middle;  }
  &__text { svg { fill: #dcdcdc; height: 8vw; }  }
  &__icon { width: 9.7222222222vw; height: 9.7222222222vw; position: relative; margin: 0 2.5vw;}
  &__icon img{ position: absolute; top: 50%; left: 50%; -webkit-transform: translateY(-50%) translateX(-50%); transform: translateY(-50%) translateX(-50%); max-height: 4vw; max-width: 10vw; width: 40%; height: 35%;  }
}
@keyframes wave {  0% {  }  100% { transform: translateX(-100%);  }}


/* sldieshow */
.c-show{
  z-index: 5;
  &__img{z-index: 5;}
  &__img-body{ width: 45vw; height: 55vw; margin-left: -10vw; overflow: hidden; margin-right: 5vw; position: relative; border-radius: 0 20px 20px 0; overflow: hidden; z-index: 5;}
  &__item    { position: absolute; width: 100%;    height: 100%; display: flex;    justify-content: center;    align-items: center; opacity: 1; transform: scale(1.0);}
  &__item    { &.is-offslide{opacity: 0; transform: scale(1.05);} transition: .8s; }
  &__item-0  { background: $deep-color; color: $white-color;}
  &__figure  { height: 35vw; text-align: right; width: 35vw; margin :0 auto;}
  .c-figure  { height: 100%;}

  //nav
  &__nav-wrap  { position: absolute; right: calc(5vw - 40px);  bottom: 30px; width: 20px; height: 20px;}
  &__nav       { position: absolute; width: 20px; height: 20px;}
  &__nav svg   { position: absolute; width: 20px; height: 20px;}
  &__indicator { cursor: default;    display: block; position: relative;}
  .arrow-gauge { z-index: 5;}
  .circle-state{ fill: transparent;  stroke-width: 6; stroke: $base-color;   z-index: 5;  stroke-dasharray: 343 360;}
  .circle-base { fill: transparent;  stroke-width: 6; stroke: #e1e1e0;  z-index: 1;}
  .c-show__indicator{height: 20px;}

  //インジゲーター
  &__arrow {transform: rotate(-90deg);position: absolute;}
  &__indicator { cursor: pointer; }
  &__indicator:first-child{margin-bottom: 10px;}
  &__indicator::before { background:$gray-color; content: ""; height: 4px; width: 4px; display: block; border-radius: 100%; position: absolute;  top: 50%;left: 50%;  transform: translateY(-50%) translateX(-50%);}
  &__indicator {
    &.is-offslide{ 
      cursor: default; 
      &::before{  transition: .5s;   background:$deep-color;    content: "";    height: 4px;    width: 4px;    display: block;    border-radius: 100%;    position: absolute;  top: 50%;left: 50%;  transform: translateY(-50%) translateX(-50%);}
      .c-show__nav{ opacity: 1; transition: .5s; } 
    }
    .c-show__nav{ opacity: 0; transition: .5s; } 
  }  
}


/* info */
.c-info{
  &__ttl{border-bottom: 1px solid $gray-color;  color: $deep-color; padding-bottom: 25px;}
  &__btn-wrap{display: flex; justify-content: flex-end; flex-wrap: wrap;}
  &__btn-wrap .c-btn{width: 18vw;}
}

