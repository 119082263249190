@include bp() {
    
    .p-fv__body,.c-sec__ttl,.c-sec__body{width: 1728px;}
    .l-footer__body,.p-allnews ,.c-sub-fv__ttl,.p-news__lists,.c-col,.c-col__inner-mini{width: 1536px;}
    .p-terms__title,.c-sub-fv__ttl h3{font-size: 60px;}
    .c-sub-fv__ttl{max-height: 756px;}
    
    .p-fv__body {        margin: 0 auto;       padding: 0;    }
    .mv{left: calc(( 100vw -  1728px)/ -2);}
    .p-fv__title .t1{font-size: 50px;}
    .p-fv__title .t2{font-size: 130px;}
    .p-fv__title .t3{font-size: 130px;}

    .spr-w1{height: 252px;}
    .spr-w2{height: 168px;}
    .spr-w3{height: 84px;}
    .spr-w4{height: 42PX;}
    // .c-sub-reed__inner{width: 100%;margin-left: 714px;}

    .c-sec__body,.c-sec__body,.c-sub-fv__ttl,.c-sec__ttl,.c-sec__body{max-width: 1728px;}
    .p-products__lists,.c-col{width: 1536px;}

    .fs-ll{font-size: 50px;}
    .fs-l{font-size: 48px;}
    .c-sub-fv__ttl{max-height: 756px;}
    .c-sub-fv__ttl h3{font-size: 60px;}

    .c-col__img-big{width: 816px;padding-top: 816px;}
    .c-col__img-mini{width: 576px; padding-top: 384px;}
    .p-news__list{width: 480px;}
    .c-col__figure{width: 630px;height: 630px;}
    .c-sec__ttl--text{font-size:50px;}
    .c-scroll-btn{width: 210px; height: 210px;}

    // .c-sec__body,.c-sec__body{width: 1512px;}
    .c-sub-reed__inner{width: 714px;margin-left: 770px;}
    .c-col__inner--ask{width: 768px;}
    .c-col__inner--wide{width: 769px;}
    // .c-col{    justify-content: space-around;}

    .c-col__img-info{width: 420px; padding-top: 420px;}
    .c-show__figure{width: 560px; height:  560px;}
    .c-show__img-body{width: 756px; height: 924px; border-radius: 20px; margin-left:calc( (1728px - 1536px) / -2);}
    .c-show__nav-wrap{right:calc(96px - 40px) ;}
    .p-products__list{width: 460px;}
    .p-products__img{padding-top:334px;}
    .p-products__list{margin-bottom: 100px;}
    .p-products__lists:after{width: 460px;}

    .c-info__btn-wrap .c-btn{width: 345px;}
    .c-sec__ttl--text{margin-left: 95px;}
    .c-col__img-profile{width: 573px;padding-top: 760px;}
    .c-col__img-job{width: 672px;padding-top: 432px;}
    .c-sec__ttl-contact,.u-title__jobs{width: 100%;}
    
    .p-allnews__thumb, .p-single__nav__thumb{width: 383px;}
    .p-allnews__thumb-inner, .p-single__nav__thumb-inner{width: 383px;padding-top: 257px;}
    .p-allnews__body, .p-single__nav__body{width: 1025px;}
    .p-allnews__text, .p-single__nav__text {width: 90%;}
    .p-news__list-inner{width: 480px;}
    .c-col__img-contact{margin-left: calc((100% - 1808px)/2);}

    .p-people__slider, .p-team__slider{    margin-left: calc( 100% - 1728px);}
    .p-people__slider--body, .p-team__slider--body{padding-left: calc( (1728px - 1536px)/2 );}
    .l-footer__left{width: 384px;}
    .l-footer__right{width: 1152px;}
    .l-footer__g-nav{width: 100%;}
    .l-footer__wrap{width: 45%;}
    .p-vision__ttl{font-size: 66px;}

    .c-figure .aw-3 {    margin-top: -50px;}

    // .c-col__canvas{ margin-left: calc((100% - 1536px )/2);    }
    .c-col__canvas canvas{left: calc((100% - 1728px - 192px )/2);margin-left: 0;}
    .c-col__canvas{width: 100%;}
    .c-col__canvas{margin-left: 0;}
    // .c-col__canvas canvas{margin-left: 0;left: calc((1536px - 1728px ));}
    .p-vision__img{height: 80vh;}
}

