/*--------------------------------------

  Header / Footer

--------------------------------------*/
.l-header {
  width: 100%;  position: fixed;  top: 0;  left: 0;  z-index: 10;    transition:backdrop-filter .3s; pointer-events: all;
  &__body { width: 90vw;    margin: 0 auto;  display: flex;    align-items: center;    justify-content: space-between;    padding: 4vw 0;  }
  &__logo { width: 150px;  }
  &__nav  { display: flex;    align-items: center; margin-right: 5vw; }
  &__nav-lists { display: flex; visibility: visible;  pointer-events: all;}
  &__nav-link  { margin-right: 50px;  }
  
  &__menu { width: 70px;    height: 70px;    border-radius: 100%;    background: $deep-color; border: 1px solid #3a5e5e;}
  &__menu button{width: 100%; height: 100%;position: relative;}
  &__menu-hamb { height: 1.4rem; left: calc(50% - 1rem); position: absolute; top: calc(50% - 0.9rem); width: 2rem; }
  &__menu-hamb line{    stroke-width: 1.5;    stroke: #fff; transition: .8s;transition-delay: 0.4s ;}
  &__menu-hamb line:nth-child(2){ transition-delay: .5s }
  &__menu-hamb line:nth-child(3){ transition-delay: .6s }
  &__menu-cross { width: 1.5rem; height: 1.5rem; position: absolute;    left: calc(50% - 0.75rem);    top: calc(50% - 0.75rem); }
  &__menu-cross line{ stroke-width: 1.5;    transition: transform .8s; transform: translate(100%,100%);   transform-origin: center center; stroke: #fff; transition-delay: 0s  }
  &__menu-cross line:first-child{ transform: translate(100%,100%);}
  &__menu-cross line:last-child{ transform: translate(100%,-100%);}
  //nav
  &__nav-list { 
    &.is-navi{
      color:$base-color; 
      .l-header__nav-link{ cursor: default; }
    }
   }
  &:hover{
    transition:backdrop-filter .3s;
    backdrop-filter: blur(1px);
  }
}
.l-footer {
  width: 100%;  height: 100%;  position: relative;  background: $deep-color;  color: $white-color;margin-top: -2px;padding-bottom: 2px;
  &__left  { width: 20vw;  }
  &__right { width: 60vw;  }
  &__body  {  width: 80vw;    margin: 0 auto;    display: flex;    justify-content: space-between;  }
  &__logo-img { width: 10vw;  }
  &__col   { display: flex;}
  &__o-col { display: flex;  }
  &__nav-list { width: 10vw;  }
  &__g-nav { width: 55vw;    justify-content: space-between;  }
  &__o-nav { border-top: 1px solid #3a5e5e;  }
  &__o-nav-link { margin-right: 40px;  }
  &__wrap  { width: 25vw;    height: 100%;  }
  &__list  { padding-right: 20px;  }

  &__list,&__nav-list {transition: opacity .4s ;}
  &__list:hover,&__nav-list:hover  {opacity: .5;transition:opacity .4s ;}
  &__ttl   { width: 100%;    border-bottom: 1px solid #3a5e5e;    padding-bottom: 25px;    margin-bottom: 25px;    opacity: 0.6;  }
  &__bottom{  display: flex;    align-items: center;}
  &__bottom small { width: 100%;      opacity: 0.6;    }

  &__round        { height: 100px; background: $deep-color; position: relative; margin-top: -1px;}
  // &__round ::after{content: "";position: absolute;width: 100vw;height: 5px; background: $deep-color;top: -2px;left: 0;}
  &__round-body   { width: 100%; top: -1px; position: relative; height: 10vh; overflow: hidden; height: 70px;  }
  &__round-item   { width: 150%; content: ''; display: block; position: absolute; background: $base-bg; height: 750%; left: 50%; border-radius: 50%; transform: translate(-50%, -86.666%); z-index: 1; }
  &::after{content: "";position: absolute;width: 100vw;height: 10vh; background: $deep-color;bottom:-10vh;left: 0;}
  &__bleed{width: 100vw;height: 5px; position: absolute;top: -4px; z-index: 5; background: #f4f1ef; display: none;}
}

/*--------------------------------------

  load

--------------------------------------*/
.p-load,.l-load{
  pointer-events:none;display:flex;justify-content:center;align-items:center;min-height:100vh;width:100vw;height:100vh;overflow:hidden;position:fixed;z-index:100;top:0;left:0;
  &__body{display:flex;justify-content:center;align-content:center;width:100%;height:100%;position:absolute;top:0;z-index:120;left:0}
  &__body-logo{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center;color:#879e9e;font-size:14px;font-weight:600}
  &__body-logo  svg{width:10vw;}
  &__body-logo path{fill:#fff}
  &__body-logo  .l-load__logo-number{padding-top:20px;width:100%;display:inline-block}
  &__inner{ width: 100vw;height: 100vh;}
  &__text{position: absolute;}
}
.p-load__svg, .l-load__svg{z-index:100;position:absolute;left:0;top:-5px;width:100%;height:calc(100% + 5px);fill:$deep-color}
.p-load__svg2,.l-load__svg2{z-index:80;position:absolute;left:0;top:-5px;width:100%;height:calc(100% + 5px);fill:$base-color}
.p-load,.l-load{opacity:1}
.u-rev{ transform: scale(1, -1);}

.p-load__inner{z-index: 50;}
.p-load__progress{
  width: 80vw; z-index: 100; position: absolute; color: white;  text-align: center;  display: flex; justify-content: space-between; margin: 0 auto;height: 100vh;
}
// .p-load_logo{width: 100px; fill: white;}

.p-load_logo { position: absolute;    top: 50%;    left: 50%;    transform: translateY(-50%) translateX(-50%);}
.p-load_logo img{width: 12.5vw; display: inline-block;}
.p-load_logo svg{width: 100px; height:200px;fill: #fff;  }
.p-load_c span{display: inline-block;}
.p-load_p,.p-load_t{font-size: 14px;}
.p-load_p{  left:  5vw; bottom: 5vw; }
.p-load_c{ position: absolute; right: -2.5vw; bottom: 5vw;  display: flex; align-items: center; overflow: hidden;}
.p-load__in{display: inline-block; padding: 10px 0 ;}
$offset: 187;
$duration: 1.4s;
.spinner { animation: rotator $duration linear infinite; width: 20px; height: 20px; margin-right: 10px;  border-radius: 100%;}
@keyframes rotator {  0% { transform: rotate(0deg); }  100% { transform: rotate(270deg); }}
.path {  stroke-dasharray: $offset;  stroke-dashoffset: 2;  stroke-width:1px; transform-origin: center; stroke:#fff; animation:    dash $duration ease-in-out infinite, }
@keyframes dash { 0% { stroke-dashoffset: $offset; } 50% {   stroke-dashoffset: $offset/4;   transform:rotate(135deg); } 100% {   stroke-dashoffset: $offset;   transform:rotate(450deg); }}






/*--------------------------------
	menu
--------------------------------*/
.p-menu {
  
  position: fixed;  top: 0;  left: 0;  width: 100vw;  height: 100vh;  z-index: 9;  opacity: 0;  visibility: hidden;  pointer-events: none;
  &__inner    { width: 100vw; height: 100vh; margin: 0vw auto; display: flex;  position:  absolute; top: 0;left: 0; align-items: center; z-index: 5;}
  &__img      { height: 32vw; width: 25vw;border-radius: 20px; overflow: hidden;position: relative; z-index: 5; position: relative;}
  &__img-in   { position: absolute;top:0; left: 0; height: 100%;width: 100%; overflow: hidden;  transform-origin: top; z-index: 10; top: 100%;}
  &__img-wrap { width: 100%; height: 100%; display: inline-block; position: relative; overflow: hidden;}
  &__tmb      { height: 35vw !important;}
  &__wrap     { width: 50%;}
  &__bg       { display: flex; opacity: 0; justify-content: center;    align-items: center;    min-height: 100vh;     position: absolute; top: 0;    z-index: 1;    width: 100%; pointer-events: none;}
  &__bg svg   { position: absolute;      left: 0;      top: 0;      width: 100%;      height: 100%;      z-index: 1;      fill: $deep-bg;    }
  &__left     { height: 100%; display: flex ; justify-content: center; align-items: center;}
  &__nav      { padding: 0 10vw;}
  &__bar      { display: block;    height: 1%;    transform: scaleY(0);    width: 1px;    position: absolute;    right: 50%;    top: 0;    transform-origin: left top;    background: $gray-color}
  //acthive
  .is-menuimg{  z-index: 15; opacity: 1; transition: .8s; filter: brightness(1); transform:  scale(1);}
  &__img-in{ opacity: 0; transition: .8s; filter: brightness(1.3);z-index: 15; top: 0;  transform:  scale(1.1);}
  &__img-in{ &.is-navi{ z-index: 15; top: 0; opacity: 1; filter: brightness(1);  transform:  scale(1);} }
  &__img-in{ &.is-default    { z-index: 15; top: 0; opacity: 1;} }

  &__nav-lists{ padding-bottom: 20px;}
  &__nav-primary {margin-left: -5px;}
  &__nav-primary   .p-menu__nav-link{ font-size: 50px; }
  &__nav-primary   .p-menu__nav-list{ padding: 0.2em 0; }
  &__nav-secondary .p-menu__nav-link{ font-size: 22px;}
  &__nav-secondary .p-menu__nav-list{ padding:.5em 0 .5em 0 }
  
  &__nav-tertiary  .p-menu__nav-list{ padding: 0.5em 0; }
  &__nav-link{color: $deep-color; position: relative; height: 100%; overflow: hidden; display: inline-block;}
  &__nav-link span {display: inline-block;}
  &__mask {fill: $base-color;}

  //acthive
  .p-menu__nav-link:hover{   span{color: $base-color; transition: .4s color;}}
  &__nav-list{ 
    width: fit-content;
    position: relative; span{transition: .4s color;}
    &.is-navi{
      .u-dot{  display: inline-block;  background:  $base-color;    border-radius: 100%;    content: "";    height: 5px; width: 5px;    left: -20px;       position: absolute;    top: 50%;    left: -20px;    transform: translate(-50%, -50%);}
      .p-menu__nav-link{ color: $base-color; }
    }
  }
  &__over{display: flex; background: rgba(0, 0, 0, 0.2);  opacity: 1; justify-content: center;    align-items: center;    width: 100vw; height: 100vh;     position: absolute; top: 0;    z-index: -1;    width: 100%; pointer-events: none;}
}
//menubtn+hover
.l-header{
  &__menu{     position: relative;transition:$ease;   position: fixed;  top: calc(4vw - 35px / 2); right: 5vw; z-index: 10;}
  &__menu:hover{    transform: scale(1.1); transition:$ease;  }
}

.is-menu-open {
  .p-menu {  opacity: 1;  pointer-events: auto;  visibility: inherit; }
  .c-menu__btn span { display: block; width: 10%; height: 1%; border-radius: 0%; background: #222; transition: 0.4s; cursor: pointer; }
  .l-header{pointer-events: none;}
  .l-header__logo{pointer-events: all;}
  .l-header__nav-lists { pointer-events: none;}
}
.c-menu__btn{
  &.is-menu-open{
  .l-header__menu-cross line{ transform: translate(0); transition-delay: 0.5s ;} 
  .l-header__menu-hamb  line{ transform: translateX(5rem);}
  .l-header__menu-hamb line{    transition-delay: 0s ;}
  .l-header__menu-hamb line:nth-child(2){ transition-delay: .1s }
  .l-header__menu-hamb line:nth-child(3){ transition-delay: .2s }
  }
}
// .mv{
//     z-index: 10000 !important;
// }
// .p-load__progress,.l-header__menu,.l-header{
//   visibility: hidden;
//     opacity: 0;
// }
