/*--------------------------------------

  single.scss

--------------------------------------*/

.p-single{
  
  h1{ font-size: 32px; font-weight: 700; line-height: 1.4; letter-spacing: 0.06rem; padding-bottom: 35px;}
  &__nav-title-wrap{display: flex;}
  &__article{ padding: 0 25vw; }
  &__title  { margin-bottom: 75px;}
  &__thumb  { width: 100%; padding-top: 67%; position: relative; border-radius: 20px; overflow: hidden; span{ width: 100%; height: 100%; background: center center no-repeat; background-size: cover; display: inline-block; position: absolute; top:0; left: 0;}}
  &__share  { width: 100%; display: flex; align-items: center; justify-content: flex-end; border-top:1px solid $gray-color; padding-top: 25px; margin-top: 70px;}
  &__share  .c-social__circle .normal{stroke: #a9b0b3;}
  &__share  &__share-text{ margin: 0 20px 0 0;}
  &__icons  { display: flex;    width: 100px;    justify-content: space-between;}
  &__thumb-wrap{ display: inline-block ; width: 100%; height: 100%; overflow: hidden; border-radius: 20px;}
  &__wrap{
    margin-top: 100px;
    h2{ font-size: 27px; font-weight: 700; padding-bottom: 30px; margin-bottom: 35px; border-bottom:1px solid $gray-color; color: $deep-color ; }
    h3{ font-size: 24px; font-weight: 700; padding-bottom: 30px; margin-bottom: 35px; border-bottom:1px solid $gray-color;}
    h4{ font-size: 19px; font-weight: 700; margin-bottom: 35px; }
    h5{ font-size: 15px; font-weight: 700; margin-bottom: 20px; padding: 10px 20px;  background : $deep-color; color: $white-color; border-radius: 100px; display: inline-block;}
    h6{ font-size: 17px; font-weight: 700; margin-bottom: 35px; }
    p { font-size: 15px; padding-bottom: 70px;line-height: 1.7; letter-spacing: 0.05rem; overflow-wrap: break-word;}
    a { color: $base-color; text-decoration: underline;}
    blockquote{background: $deep-bg;padding: 35px; border-radius: 20px; font-size: 14px; line-height: 1.7; letter-spacing: 0.05rem; margin-bottom: 70px; p {padding-bottom: 20px; font-size: 15px;}}
    img{width: 90%; border-radius: 20px; margin: 0 5% 70px 5%; height: 100%;}

    ol,ul {    margin-bottom: 35px;}
    li{ font-size: 15px; line-height: 1.7; letter-spacing: 0.05rem;position: relative; padding-left: 1.5rem;  margin-bottom: 5px;}
    li::before{content: ""; background: $base-color; border-radius: 50%; display: block; position: absolute; left: 0; top: 1.2rem; width: 4px; height: 4px;}
    figure{height: 100%;}
    h1,h2,h3,h4,h5,h6{      p{        padding-bottom: 0;      }
  }
  .wp-caption{      img{        margin-bottom: 10px;      }    }
  }
  &__nav{width: 100%;}
  &__nav__article{align-items: flex-start;}
  &__nav__info{display: block;}
  &__nav__inner{width: 80vw;margin: 0 auto;}
  &__nav-title{width: 20vw;}
  &__nav__text{width: 100%;}
  &__nav__body{width: 35vw;}
  &__nav__list{ border-bottom: 1px solid $gray-color; border-top: none;}
}
