/*--------------------------------------

  Utility

--------------------------------------*/

/* 下線 */
.u-green__line {  border-bottom: 1px solid #3a5e5e;}
.u-topline     {  border-top: 1px solid $gray-color;}
.u-noline      {  border-bottom: none; border-top: none;}

/* テキスト */
.u-green__text {  color: $base-color;}
.u-deep__text  {  color: $deep-color;}
.u-white__text {  color: $white-color;}

/*　状態　*/
.is-hidden { height: 0; position: relative; width: 100%;  overflow: hidden; transition: 1s cubic-bezier(0.16, 1, 0.3, 1); opacity: 0; z-index: 5; }
.is-active { height: var(--h); position: relative; width: 100%;  overflow: inherit; transition: 1s cubic-bezier(0.16, 1, 0.3, 1); opacity: 1;  z-index: 5}
.is-stop {  animation-play-state: paused;}
.is-loading{cursor: wait;}

/*矢印*/
.u-arrow--external{  transform: rotate(-45deg);}
.u-arrow--prev{  transform: rotate(-90deg);}
.u-arrow--next{  transform: rotate(90deg);}
.u-arrow--bottom{  transform: rotate(180deg);}
.u-arrow--explore{ transform:  rotate(45deg);    height: 10px;position: relative;width: 8px;}
.u-arrow--explore path{ fill: $deep-color;}

/**/
.u-bg-green{background: $base-color;z-index: 2; }
.u-bg-deep{background: $deep-color;z-index: 0; position: absolute; }
.u-bg-white{background: #19a65c;z-index: 0; position: absolute; display: none;}
.u-bg-base{background: $deep-bg ;z-index: 1; }
.u-border{border: #275050 1px solid ; }

/* temp */
.u-list {position: relative; padding-left: 1.5rem;  margin-bottom: 5px;}
.u-list ::before{content: ""; background: $base-color; border-radius: 50%; display: block; position: absolute; left: 0; top: 0.65em; width: 4px; height: 4px;}
.u-list--green ::before{background: $deep-color;}

/* link */
.u-br{ display: inline-block;}
.u-map__link{    vertical-align: middle;    padding: 0px 8px;    border: 1px solid $gray-color;border-radius: 100px;    position: relative;    overflow: hidden;    display: inline-block;    position: relative;    margin-bottom: 3px;}
.u-map__link{ color: rgba(34, 34, 34, 0.501);}

/*位置*/
.u-ttl__mini{ position: absolute; right: 5vw; top: 0; top: 30%;    align-items: flex-start;  }
.u-title__jobs{width:100%;}
.u-text__right{text-align: right;}
.u-list{width: 100%;}
.show-t{display: none;}

/* ベージュbg */
.u-deep-bg{background: $deep-bg;}

/*--------------------------------------

  Lazy+image
  
--------------------------------------*/
.js-img {  opacity: 0;  position: absolute;  width: 100%;  height: 100%;  top: 0;  left: 0;  z-index: 1; filter: brightness(1.3);}
.js-bg {   width: 100%;  height: 100%;  background-position: center center;  background-size: cover;  filter: brightness(1.3)}
.is-loaded {  opacity: 1;  transition: opacity .5s ,filter 0.5s; &.u-no{transition: none;} filter: brightness(1);}
.js-img__bg { background: #222; } //bgつけておく場合
.js-img--bg{ background: no-repeat; background-size: cover; background-position: center center;}

/*----------------------------------
	
  SmootherScroll

----------------------------------*/

#sc-wrap{  height: 100%;  width: 100%;  top: 0;  left: 0;  right: 0;  bottom: 0;}
main{  background: $base-bg;}


/*--------------------------------------

  Animation

--------------------------------------*/
/*--------------------------------------
  Hover
--------------------------------------*/
.js-link-text{
  &__in{position: relative; height: 50%; overflow: hidden; }
  &__wrap{  position: relative; overflow: hidden; display: inline-block; display: flex; align-items: center;}
  &__wrap span{  display: inline-block; }
  &__label{ display: inline-block;}
  &__hover{ width: 100%;  position: absolute;    top: 100%;    left: 50%;    transform: translate(-50%, -50%) translateY(100%);    display: inline-block;    overflow: hidden;   }
}
.js-link-btn{transition:transform .5s;
  &:hover{    transform: scale(.95); transition:transform .5s;  }
  &__in {     height: auto;    overflow: hidden;    position: relative;    z-index: 10; ;
  span{ padding: 10px 0 ; }
}
}

.js-link-mini{
  height: 100%;position: relative;display: inline-block; transition: .35s color;
  &__wrap{position: absolute; top: 0;left: 0;width: 100%; height: 100%; opacity: 0; border-radius: 40px;z-index: -1;transition: .35s transform;}
  &:hover{
    color: $white-color;
    transition: .35s color;
    .js-link-mini__wrap{opacity: 1; transition: .35s opacity;}
  }
}

/*--------------------------------------
  Hover
--------------------------------------*/
.js-link{
  cursor: pointer;
  &-line{ position: relative;
  &::before, &::after{    content: ""; position: absolute;    bottom: 0;    left: 0%;    width: 100%;    height: 1px;  background: $deep-color; transition: $ease;}
  &::before{    transform: scale(1, 1);    transform-origin: left top; }
  &::after{    transform: scale(0, 1);    transform-origin: right top;  transition-delay: 0.5s;}
  }
  &:hover { 
    .p-single__nav__thumb-inner,
    .p-allnews__thumb-inner{
        transition:transform 1s;
        transform: scale(1.05);
    }
    .js-link-line{
      &::before{
        transform: scale(0, 1);
        transform-origin: right top;
      }
      &::after{
        transition-delay: 0.2s;
        transform: scale(1, 1);
        transform-origin: left top;}
      }
    }
}
/*--------------------------------------
  TTL
--------------------------------------*/
.js-ttl{ display: inline-block; overflow: hidden; height: 100%;
  &__text{ display: inline-block;transform-origin: left top; }
}
.u-in   {display: inline-block; opacity: 0; transform: translateY(30px);transition: $easeIn;}
.u-enter{display: inline-block; opacity: 1; transform: translateY(0px); transition: $easeIn;}

.l-header__logo,.l-header__nav-lists{transform:translateY(0px) ;  opacity: 1; transition: $ease;}
.down{ .l-header{pointer-events: none;} .l-header__logo,.l-header__nav-lists{transform:translateY(-30px);transition: $ease; opacity: 0; pointer-events: none;}}
.is-text__white{
  .l-header__nav-list{color: white;&.is-navi{color:$base-color ;}}

}