@include bp(tablet-large) {
    .p-allnews__article, .p-single__nav__article{align-items: flex-start;}
    .u-list{width: 100%;}
    .show-t{display: none;}
    .l-footer__o-col{flex-flow: wrap;}
    // .l-footer__right{ .l-footer__g-nav{display: block; } .l-footer__wrap{width: 100%;} .l-footer__nav-lists{width: 50%;}}
    .l-footer__list { padding: 0 0px 20px 0px;}
    //
    .p-menu__wrap{ width: 100%;}
    .p-menu__nav { padding: 0 40px;width: 100%;}
    .p-menu__nav-primary .p-menu__nav-link{ font-size: 35px; }
    .p-menu__nav{padding-left: 15%;}
    .p-menu__left{display: none;}
    
}
@include bp(tablet) {
    .u-tab-col { 
        display: block;
        .c-col__inner         { width: 100%;}
        .c-col__inner         { width: 100%;}
        .c-col__inner-lead    { padding: 0;}
        .c-col__inner-left    { padding: 0;}
        .c-col__inner-right   { padding: 0;}
        .c-col__text-mini     { padding: 0;}
        .c-col__text-right    { margin: 0;}
        .c-col__text-contact  { padding: 0;}
    }
    .hide-t { display: none; }
    .show-t { display: block;}
    .p-products__list{width: calc(50% - 20px);}
    .p-products__img{ padding-top: 70%;}
    .p-terms__inner,.p-single__article{padding: 0 10vw;}
    .c-sub-reed__inner{ width: 80vw; margin: 0 auto;}
    .c-sub-fv__img{ height: 55vh;}
   
    .c-sec__ttl--text         { font-size: 27px;  margin-bottom: 30px;}
    .c-sec__ttl--text:before  { width: 5px;height: 5px; margin-left: -10px;}
    .u-noline { border-bottom: 1px solid #e9e9e9;}

    .p-porker .c-col{align-items: flex-start;}
    .c-show{
        &.c-show-home{
        &__img-body{ width: 100vw; margin-left: -20px; height:120vw; border-radius: 0;}
        &__figure  { width: calc(100vw - 40px); height: calc(100vw - 40px); margin-top: -5vw;}
        .c-figure  { height: 100%;}
        //nav
        &__nav-wrap{ left: 0px; bottom: -30px; }
        &__nav-body{ display: flex; width: 50px; justify-content: space-between; }
        &__indicator{ width: 20px; }
        //
        .u-show-mission{     border-radius: 20px;    height: 60vw;    margin: 0 auto;    overflow: hidden;    width: 90vw;}
        }  
    }

    .c-info__btn-wrap{display: block;}
    .c-info__btn-wrap .c-btn{width: 100%;}
    .c-info__btn-wrap .c-btn:first-child{margin-bottom: 15px;}
    .u-no-tab{align-items: flex-start;}
    //commpany
    .c-col__img-profile   { padding-top: 50vw;    width: 80vw;    margin-left: -80px;  margin: 0 auto;}

    .p-allnews__info, .p-single__nav__info{ display: block; }
    .p-allnews__text {width: 100%;}

    .spr-w1 { height: 110px;}
    .spr-w2 { height: 90px;}
    .spr-w3 { height: 50px;}
    .spr-w4 { height: 30px;}

    //menu
    .p-menu__wrap{ width: 100%;}
    .p-menu__nav { padding: 0 40px;width: 100%;}
    .p-menu__nav-primary .p-menu__nav-link{ font-size: 35px; }
    .p-menu__nav{padding-left: 15%;}

  
    //mission
    .p-vision__coment {width: 70%;}

    //footer
    .l-footer__o-col{flex-flow: wrap;}
    .l-footer__right{ .l-footer__g-nav{display: block; } .l-footer__wrap{width: 100%;} .l-footer__nav-lists{width: 50%;}}
    .l-footer__list { padding: 0 0px 20px 0px;}

    //service
    .service-body{
      
      .c-col{display: block;}
      .c-col__inner{width: 100%;}
      .c-col__reverse       { display: flex; flex-wrap: wrap; flex-direction: column-reverse; }
    }
          .c-col__figure{width: 60vw; height: 60vw; margin: 0 auto 5vw auto;}
    //home
    .home-mission{
        .c-show{
          //nav
          &__nav-wrap{ left: 0px; bottom: -30px; }
          &__nav-body{ display: flex; width: 50px; justify-content: space-between; }
          &__indicator{ width: 20px; }
          //
        }
    }
    //contact
    .c-col__img-contact{width: 90vw;}
}
