/*====================================

	mobile

====================================*/

@include bp(mobile) {
  //setting
  //home
  .p-fv {
    &__title { bottom:120px; }
    &__title .t1    { font-size:6vw; margin-bottom: 12px;}
    &__title .t2,.t3{ font-size: 10.5vw; }
    &__title .t3    { color: $base-color; }
  }
  .p-fv__scroll-btn {bottom:20px;}
  .p-fv__body       {padding: 0 20px; }
  .p-load_logo img{width: 150px; display: inline-block;}
  #sc-wrap{overflow: hidden;position: relative;}

  //compornent
  .c-sec__body              {	width: 100%;padding: 0 20px; }
  .c-sec__ttl--text         { font-size: 27px;  margin-bottom: 30px;}
  .c-sec__ttl--text:before  { width: 5px;height: 5px; margin-left: -10px;}
  

  .c-col                { display: block; padding: 0 20px; width: 100%;}
  .c-col__news          { display: block;}
  .c-col__inner         { width: 100%;}
  .c-col__inner-lead    { padding: 0;}
  .c-col__inner-left    { padding: 0;}
  .c-col__inner-right   { padding: 0;}
  .c-col__inner-porker  { margin: 0; padding: 0 20px;}
  .c-col__inner-slidenav{ justify-content: space-between;}
  .c-col__img-profile   { padding-top: 70vw;    width: 100vw;    margin-left: -80px; }
  .c-col__img-porker    { height: 130vw; margin-left: -20px; margin-right: 0; overflow: hidden; width: 100vw;}
  .c-col__img-job       { padding-top: 67%;width: 100%;}
  .c-col__img-contact   { margin-left: -40px;    padding-top: 78.7%;    width: calc(100vw - 40px);}
  .c-col__text-mini     { padding: 0;}
  .c-col__text-right    { margin: 0;}
  .c-col__text-contact  { padding: 0;}
  .c-col__canvas        { height: 60vw;margin-left: -40px;width: 100vw;}
  .c-col__reverse       { display: flex; flex-wrap: wrap; flex-direction: column-reverse; }
  .c-col__figure        { width: calc(100vw - 40px); height: calc(100vw - 40px);margin: 0 0 0 -20px;}

  .c-btn__circle      { width: 60px;height: 60px;}
  .c-btn__circl-mini  { width: 20px;height: 20px;}
  .c-btn-company      { position: absolute; right: 0;cursor: pointer; z-index: 5;}
  .c-scroll-btn       { width: 80px; height: 80px;}
  .c-scroll-btn__arrow{ width: 20px; height: 16px; top: -2px;}


  .c-figure{
    &__label-01{ position: absolute; left: 10%;  top:23%; } 
    &__label-02{ position: absolute; right: -8%; top:23%; }
    &__label-03{ position: absolute; bottom: -8%; left: 50%;}
  }
  .c-figure__item{width: 30%;height: 30%;}
  .c-figure__ttl-inner{width: 40%;}
  .c-figure .aw-3 { margin-top: -7vw;}

  
  .p-news__lists      { display: block;}
  .p-news__list object{ display: inline-block; width: 100%; position: relative; width: fit-content;}
  .p-news__list-inner { width: 100%;}
  .c-news__list-text  { margin-bottom: 10px;}

  .c-news__desc-line{border: none;  margin-bottom: 0px;padding-bottom: 18px; }
  .p-news__list-inner{padding-bottom: 30px; border-bottom: 1px solid #e4e4e4;  }

  .p-wave__text svg{ height: 15vw;}
  .p-wave__icon    { height: 19.722222vw;    width: 19.722222vw;}

  .fs-ll{ font-size: 27px;}
  .fs-l { font-size: 24px;}
  .fs-xm{ font-size: 20px;}
  .fs-m { font-size: 16px; letter-spacing: .03em;}
  .fs-s { font-size: 14px;}

  .spr-w1 { height: 110px;}
  .spr-w2 { height: 90px;}
  .spr-w3 { height: 50px;}
  .spr-w4 { height: 30px;}
  .spr-w6 { height: 10px;}
  .show-m { display: block;}
  .hide-m { display: none;}

  //header
  .l-header       { position: fixed; top:0; right: 0; width: 100%; overflow: hidden;  overflow: hidden;}
  .l-header__body  { padding: 8vw 0;}
  .l-header__logo { width: 150px ;}
  .l-header__menu { height: 60px;    width: 60px; border: 1px solid #3a5e5e; top: calc(8vw - 25px / 2);}
  .p-load_c { position: absolute; bottom: 30px; right: 30px;}


  //menu
  .p-menu__wrap{ width: 100%;}
  .p-menu__nav { padding: 0 40px;width: 100%;}
  .p-menu__nav-primary .p-menu__nav-link{ font-size: 30px; }
  .p-menu__nav-list.is-navi .u-dot{left: -15px;}
  .p-menu__nav-lists{padding-bottom: 15px;}
  .p-menu__nav-secondary .p-menu__nav-list, .p-menu__nav-tertiary .p-menu__nav-list{padding: 0.35em 0;}
  .p-fv__round,.p-fv__round-body{height: 60px;}
  .p-fv__round-item{height: 80px;    transform: translate(-50%,-26.666%); width: 120%;}
  .mv{top: -30px;}
  .c-news__desc-cate{text-align: center;}

  //footer
  .l-footer__round-item{ height: 200%;}
  .l-footer__body{display: block; padding: 0 40px; width: 100%;}
  .l-footer__left{width: 100%;}
  .l-footer__logo-img{width: 156px;}
  .l-footer__g-nav{flex-wrap:wrap ;}
  .l-footer__g-nav{width: 100%;}
  .l-footer__right{width: 100%;}
  .l-footer__wrap{width: 100%;}
  .l-footer__nav-lists{width: 100%;}
  .l-footer__o-col{display: block;}
  .l-footer__col {    display: flex;    justify-content: flex-end;  }
  .l-footer__list{padding:0 0 0 20px;}
  .l-footer__bottom {width: calc(100vw - 40px);    margin: 0 0 0 -20px;}
  .l-footer__o-nav{width: calc(100vw - 40px);    margin: 0 0 0 -20px;}
// .l-footer{    &::after{content: none;position: absolute;width: 100vw;height: 10vh; background: $deep-color;bottom:-10vh;left: 0;}}

  .u-noline { border-bottom: 1px solid #e9e9e9;}
  .u-ttl__mini{ top:10%;}

  //pages
  .c-sub-fv       {position: relative; z-index: 8;}
  .c-sub-fv__ttl h3{    font-size: 8.2vw; line-height: 1.4; width: calc(100% + 30px );}
  .c-sub-fv__ttl{height: auto; padding-bottom: 100px;}
  .c-sub-fv__ttl-small{ padding-bottom: 0px;}
  .c-sub-fv__img {height: 95vw; width: 95%;}
  .c-sub-fv__img div{background-position:center ;}
  .c-sub-reed__inner { margin-left: 0; width: 100%; padding: 0 20px;}
  .js-ttl{line-height: 1.1;}
  .c-dropdown{border-radius: 25px; width: 60%;}

  //mission
  .c-ask__nm{width: 30px;}
  .c-ask__text{width: calc(100% - 60px);}
  .c-ask__q{ width: calc(100% - 35px);} 
  .c-ask__jp { font-size: 20px; line-height: 1.3; letter-spacing:0.01em ;}
  .c-ask__btn{  margin-top: 2rem;}
  .c-ask__body--text{    margin-top: 30px;    padding: 30px;}
  .c-ask__title{align-items: flex-end;}
  .c-btn-acc{    bottom: 1.5rem;}
  .c-ask:first-child{.c-btn-acc{bottom:0;}}

  .c-col__inner--info{margin: 0;}
  .c-col__img-info{ width: 100%; padding-top: 100%;}
  // .p-vision__ttl{    font-size: 8vw;    width: 100%;}
  // .p-vision__img{width: 100%; border-radius: 0; position: relative;}
  // .p-vision__texts{width: calc(100% - 80px); margin: 0 40px;}
  // .p-vision__coment{width: 100%;}
  // .p-vision__img-item{ background-position: 70% top;  height: 120vh; opacity: 1;}
  // .p-vision__img::after{content: "";height: 100%;width: 100%;background: #222; position: absolute; top: 0;z-index: 1;display: block;}
  .p-vision__ttl{    font-size: 8vw;    width: 100%;}
  .p-vision__texts{color: #222;}
  .p-vision__img-bg{position: relative;height: 35vh;}
  .p-vision__img-item{    background-position: right;}

  .p-vision__img-bg{   width: 100%; margin-left: 0vw; border-radius: 0px; }
  .p-vision__img-item{ margin-left:0vw; }

  .c-show{
    &__img-body{ width: 100vw; margin-left: -20px; height:130vw; border-radius: 0;}
    &__figure  { width: calc(100vw - 40px); height: calc(100vw - 40px); margin-top: -5vw;}
    .c-figure  { height: 100%;}
    //nav
    &__nav-wrap{ left: 0px; bottom: -30px; }
    &__nav-body{ display: flex; width: 50px; justify-content: space-between; }
    &__indicator{ width: 20px; }
    //
    .u-show-mission{ height: 70vw; width: calc( 100vw - 40px );    margin: 0 auto;    border-radius: 20px;overflow: hidden;}
  }
  .c-figure{
    &__ai    {   transition-delay: .1s; }
    &__ict   {   transition-delay: .2s; }
    &__iot   {   transition-delay: .3s; }
    &__label {   transition-delay: .1s;}
  }

  //home
  .c-col__canvas canvas{margin: 0 auto;}

  //service    
  .p-products__list{width: 100%; margin-bottom: 60px;}
  .p-products__img{padding-top: 71.6%;}
  .c-info__btn-wrap{display: block;}
  .c-info__btn-wrap .c-btn{width: 100%;}
  .c-info__btn-wrap .c-btn:first-child{margin-bottom: 15px;}


  //company
  .p-team__slider{.c-btn-acc{top: 0;}}
  .p-team__slider--body,.p-people__slider--body{width: calc(100vw - 60px);}
  .p-team__slide-current,.p-people__slide-current{ width: 20vw;height: 2px;}
  .p-team__slide--text{padding-bottom: 35px;}
  .p-company__wrap{ height: 0; position: relative; width: 100%;  overflow: hidden; transition: 1s cubic-bezier(0.16, 1, 0.3, 1); opacity: 0;  }

  //careers
  .p-explore{
    .p-allnews__article,.p-explore__link{display: block;}
    .p-allnews__body,.p-allnews__thumb,.p-allnews__text,.p-explore__link-wrap{width: 100%;}
    .p-allnews__info{display: block;}
    .c-btn_body{width: 100%; margin: 0;}
    .p-explore__link-item:nth-child(even){    margin-left: 10px;}
    .p-explore__link-item:nth-child(odd){     margin-right: 10px;}
    .p-explore__link-item { width: calc(50% - 10px);}
  }
  .p-jobs__body{width: calc( 100% - 40px); margin: 0 20px;}
  .p-jobs{ .c-col{padding: 0;}}
  .c-col__text-job{margin: 0;}


  //news
  .p-allnews__thumb{width: 90%;display: flex;align-items: center;}
  .p-allnews__thumb-explore{height: 100%; display: block;}
  .p-allnews_thumb-wrap,.p-allnews__info,.p-allnews__article{display: block;}
  .p-allnews__body,.p-allnews__info,.p-allnews__text{width: 100%;}
  .p-allnews_thumb-wrap{width: 50%;}
  .p-allnews{
    .c-news__desc{ padding-left: 20px;}
    .c-news__desc-data{padding-bottom:10px ;}
    &__article .show-m{display: block;}
  }
  .p-single__nav,.p-allnews{&__article .show-m{display: block;}  }
  .c-dropdown-list,.c-dropdown__title{padding: 10px 30px;}

  //u
  .is-active { height: var(--h); position: relative; width: 100%;  overflow: inherit; transition: 1s cubic-bezier(0.16, 1, 0.3, 1); opacity: 1;  }
  .is-off{opacity: 0;visibility: hidden; transition: .5s;}


  //single
  .p-single__article   { padding: 0 40px;}
  .p-single__title     { margin-bottom: 60px;}
  .p-single__share     { margin-top:20px; justify-content: space-between; }
  .p-single            { h1{ font-size: 30px; padding-bottom: 35px;} }
  .p-single__nav__body { width: 50%;}
  .p-single__nav__thumb{ width: calc( 50% - 40px );margin-left: 0;}
  .p-single__nav__list:first-child{ border-top: 1px solid $gray-color; }
  .p-single{.p-allnews__thumb-wrap, .p-single__nav__thumb-wrap{border-radius: 5px;}}
  .p-allnews__thumb-wrap, .p-single__nav__thumb-wrap{border-radius: 10px;}
  .p-single{.p-single__nav__body{width: 55%;.spr-w5{height: 10px;}.c-news__desc {    align-items: center;    display: flex;    width: 100%;    justify-content: space-between;}}}
  .p-single__nav__text.fs-m {    font-size: 15px;    line-height: 1.6;}
  .p-single__wrap{
    margin-top: 60px;
  
    h2{ font-size: 24px; line-height: 1.5;}
    h3{ font-size: 21px; line-height: 1.6;}
    h4{ font-size: 17px; line-height: 1.6;}
    h5{ font-size: 15px; line-height: 1.6; padding: 10px 30px;}
    h6{ font-size: 16px; line-height: 1.8}
    blockquote{padding: 40px;}
    img{width: 90%; border-radius: 20px; margin: 0 5% 70px 5%;}

    ul{ margin-bottom: 35px;}
    li{ font-size: 17px; line-height: 1.7; letter-spacing: 0.05rem;position: relative; padding-left: 1.5rem;  margin-bottom: 5px;}
    li::before{content: ""; background: $base-color; border-radius: 50%; display: block; position: absolute; left: 0; top: 1.2rem; width: 4px; height: 4px;}

    p{font-size: 16px;}
  }
  .p-single__nav__article{flex-wrap: wrap; justify-content: space-between;}
  .p-single__nav-title{width: 100%; padding-bottom: 20px ;} 



  //privacy
  .p-terms{
    &__inner    { padding: 0 40px; }
    &__title    { font-size: 8.5vw;   line-height: 1.4;}
    &__sec      { width: 100%;}
    &__sec-inner{ padding:0 0;}
    &__sec-nm   { left: 0px; top: 0rem; font-size: 12px;}
    &__sec-ttl  { padding-left:30px; margin-left: 0px; line-height: 1.5;}
    &__wrap{
        border-top: 1px solid $gray-color;
        blockquote{border-radius: 10px;}
        ul{ margin-bottom: 35px;}
        ol{
          margin-left: 3rem;
          li{list-style: decimal;padding-left:0;}
          li::before{content:none;}
          li::marker{ font-weight: 600; color: $base-color;}
        }
        li{ font-size: 14px; line-height: 1.7; letter-spacing: 0.05rem;position: relative; padding-left: 1.5rem;  margin-bottom: 5px;}
        li::before{content: ""; background: $base-color; border-radius: 50%; display: block; position: absolute; left: 0; top: 1.2rem; width: 4px; height: 4px;}
      }
  }

  //load
  .p-load__inner,.p-menu__bg,.l-load__inner{transform: scaleX(1.6);}
  // .p-vision__img{height: 110vh;}
  .p-vision__coment {width: 100%;   /* position: absolute;    bottom: 10%;    margin: 0;*/}  
  .p-vision__img-item{opacity: 1; transform: scale(1);}
  .p-load__progress{position: absolute;top: 0; left: 0;width: 100%;  }
  .p-load_logo { position: absolute;    top: 50%;    left: 50%;    transform: translateY(-50%) translateX(-50%);}


  //compnay
  .p-explore{
    .c-news__desc{padding: 0;}
  }
  //page
  .p-pagination .page-numbers, .p-pagination__page{display: none;}
  .p-pagination__inner{align-items: center;}
  
}